import { ACDB_TABLE_IS_SHOWN, ACDB_TABLE_SHOWN_RESET } from "../actions/types";

const INITIAL_STATE = {
    isVisible: false
}

const tableVisibilityReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ACDB_TABLE_IS_SHOWN:
            return {...state, isVisible: action.payload}
        case ACDB_TABLE_SHOWN_RESET:
            return INITIAL_STATE
        default:
            return state;
    }
}

export default tableVisibilityReducer;
import { GET_ALL_PLANTS, RESET_ALL_PLANTS } from "../actions/types";

let initialState = {}

const plantsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_PLANTS:
            return {...state, data: action.payload};
        case RESET_ALL_PLANTS:
            return initialState;
        default:
            return state;
    }
}

export default plantsReducer;
import React from 'react';
import { connect } from 'react-redux';
import Modal from '../components/Modal';

const ActiveAlarms = (props) => {

    const { role, alarms, plantID } = props

    var alarmArray;

    if (role === 'superAdmin' && alarms) {
        alarmArray = alarms
    } else if (alarms) {
        alarmArray = alarms.filter((alarm)=>{
            return plantID.includes(alarm.plantID)
        })
    }

    const content = 
    Array.isArray(alarmArray) ? alarmArray.map((alarm, index)=>{
         return (
             <div key={index}>{role === 'superAdmin' ? <><span style={{paddingRight: 20}}>{alarm.plantID}</span><span>{alarm.almDesc}</span></> : <span>{alarm.almDesc}</span>}</div>
             
         )
     })
     :
     null

    return (
        <Modal content={content}/>
    );
}

const mapStateToProps = (state) => {
    return {alarms: state.activeAlarms.data ? state.activeAlarms.data : null, role: state.auth.user.payload.role, plantID: state.plantID.data ? state.plantID.data : null}
}

export default connect(mapStateToProps)(ActiveAlarms);
import { GET_PLANT_MQTT_INTERMEDIATE, RESET_PLANT_MQTT_INTERMEDIATE } from "../actions/types";

let initialState = {}

const plantReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PLANT_MQTT_INTERMEDIATE:
            return {...state, data: action.payload};
        case RESET_PLANT_MQTT_INTERMEDIATE:
            return initialState;
        default:
            return state;
    }
}

export default plantReducer;
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Table } from 'semantic-ui-react';
import { getMonthlyReport } from '../actions/plantAction';

const ReportPage = (props) => {

    const {plantID, getMonthlyReport, report} = props

    const getYear = new Date().toLocaleString('en-us',{year:'numeric'})


    useEffect(()=>{

        if (plantID) {
            
            getMonthlyReport({plantID: plantID, year: getYear})
        }

    }, [getMonthlyReport, plantID, getYear])


    if (!plantID && !report) {
        return (
            <div>Loading...</div>
        )
    }

    let sortedReports = report ? report.sort((a, b)=>{
                            return (
                                new Date(`${a.month} ${a.year}`) - 
                                new Date(`${b.month} ${b.year}`)
                            )
                        })
                        : null


    return (
        <div>
            <div >
                <div style={{justifyContent: "center", flexDirection: "column", textAlign: "center"}}>
                <h1>Monthly Report for year {getYear}</h1>
                </div>
            </div>
            <div style={{paddingTop: 30}}>
            {report ? <Table striped unstackable style={{overflow: "auto"}}>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>MONTH</Table.HeaderCell>
        <Table.HeaderCell>YEAR</Table.HeaderCell>
        <Table.HeaderCell>VCB ENERGY GENERATION</Table.HeaderCell>
        <Table.HeaderCell>PERFORMANCE RATIO</Table.HeaderCell>
        <Table.HeaderCell>PLANT AVAILABILITY</Table.HeaderCell>
        <Table.HeaderCell>GRID AVAILABILITY</Table.HeaderCell>
        <Table.HeaderCell>IRRADIANCE</Table.HeaderCell>
        <Table.HeaderCell>VCB IMPORT</Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>
    {sortedReports.map((sortedReport, index)=>{
        return (
            <Table.Row key={index}>
                <Table.Cell>{sortedReport.month}</Table.Cell>
                <Table.Cell>{sortedReport.year}</Table.Cell>
                <Table.Cell>{parseFloat(sortedReport.report[0].sumVCTKWH).toFixed(2)} kWh</Table.Cell>
                <Table.Cell>{parseFloat(sortedReport.report[0].avgPRTOD).toFixed(2)} %</Table.Cell>
                <Table.Cell>{parseFloat(sortedReport.report[0].avgPAVLTOD).toFixed(2)} %</Table.Cell>
                <Table.Cell>{parseFloat(sortedReport.report[0].avgGRDAVLT).toFixed(2)} %</Table.Cell>
                <Table.Cell>{parseFloat(sortedReport.report[0].sumIRTOD).toFixed(2)}</Table.Cell>
                <Table.Cell>{parseFloat(sortedReport.report[0].sumVCBTODAYIMPKWH).toFixed(2)} kWh</Table.Cell>
                
        </Table.Row>
        )
    })

    }
    </Table.Body>
  </Table> 
  :
  <div style={{textAlign: "center"}}>
    <h1>No Data Found</h1>
    </div>}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        plantID: state.plantID ? state.plantID.data: null,
        report: state.report.data ? state.report.data : null
    }
}

export default connect(mapStateToProps, {getMonthlyReport})(ReportPage);
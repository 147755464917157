import { GET_REPORT, RESET_GET_REPORT} from "../actions/types";

let initialState = {}

const reportReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_REPORT:
            return {...state, data: action.payload};
        case RESET_GET_REPORT:
            return initialState;
        default:
            return state;
    }
}

export default reportReducer;
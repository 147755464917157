import React from 'react';
import { connect } from 'react-redux';
import {Menu, Table } from 'semantic-ui-react';


const InverterTable = (props) => {
    
    const renderInvData = (tagKey) => {
        return props.plant.data.data.transformer.map((trf)=>{
            if (trf.acdb) {
                return trf.acdb.map((ac)=>{
                    return ac.inverter.map((inv, index)=>{
                        if (inv.tags) {
                            return (
                                <Table.Cell key={index}>{parseFloat(getInvData(inv, tagKey)).toFixed(2)}</Table.Cell>
                            )
                        } else {
                          return null
                        }
                        
                    })
                })
            } else if(trf.inverter) {
                return trf.inverter.map((inv, index)=>{
                    if (inv.tags) {
                        return (
                            <Table.Cell key={index}>{parseFloat(getInvData(inv, tagKey)).toFixed(2)}</Table.Cell>
                        )
                    } else {
                      return null
                    }
                })
            } else {
              return null
            }
        })
    }

    const getInvData = (inv, tagKey) => {
        return inv.tags.filter((tag)=>{
            return tag.tagID === tagKey
        }).reduce((acc, obj)=>{
            return obj.tagValue
        }, 0)
    }

    const getInvID = () => {
        return props.plant.data.data.transformer.map((trf)=>{
            if (trf.acdb) {
                return trf.acdb.map((ac)=>{
                    return ac.inverter.map((inv, index)=>{
                        return (
                            <Table.HeaderCell key={index}>INVID: {inv.invID}</Table.HeaderCell>
                        )
                    })
                })
            } else if(trf.inverter) {
                return trf.inverter.map((inv, index)=>{
                    return (
                        <Table.HeaderCell key={index}>INVID: {inv.invID}</Table.HeaderCell>
                    )
                })
            } else {
              return null
            }
        })
                
    }

    if (!props.plant.data) {
        return (
            <div>Loading...</div>
        )
    }

    return (
        <div style={{overflow: 'auto', height: '75vh'}}>
        
        <Menu style={{alignItems: 'center', justifyContent: 'center'}}>
            <div style={{fontSize: 50}}>INVERTER</div>
        </Menu>
        
        
        <Table striped color='grey' inverted celled padded='very' style={{margin: 20}}>
<Table.Header>
  <Table.Row>
    <Table.HeaderCell></Table.HeaderCell>
    {getInvID()}
  </Table.Row>
</Table.Header>

<Table.Body>
  <Table.Row>
    <Table.Cell>PHASE A CURRENT (A)</Table.Cell>
    {renderInvData('C1')}
  </Table.Row>
  <Table.Row>
    <Table.Cell>PHASE B CURRENT (A)</Table.Cell>
    {renderInvData('C2')}
  </Table.Row>
  <Table.Row>
    <Table.Cell>PHASE C CURRENT (A)</Table.Cell>
    {renderInvData('C3')}
  </Table.Row>
  <Table.Row>
    <Table.Cell>A-B LINE VOLTAGE (V)</Table.Cell>
    {renderInvData('V1')}
  </Table.Row>
  <Table.Row>
    <Table.Cell>B-C LINE VOLTAGE (V)</Table.Cell>
    {renderInvData('V2')}
  </Table.Row>
  <Table.Row>
    <Table.Cell>C-A LINE VOLTAGE (V)</Table.Cell>
    {renderInvData('V3')}
  </Table.Row>
  <Table.Row>
    <Table.Cell>DC POWER (KW)</Table.Cell>
    {renderInvData('DC_PWR')}
  </Table.Row>
  <Table.Row>
    <Table.Cell>ACTIVE POWER (KW)</Table.Cell>
    {renderInvData('ACT_PWR')}
  </Table.Row>
  <Table.Row>
    <Table.Cell>TODAY GENERATION (kWh)</Table.Cell>
    {renderInvData('DLY_PWR')}
  </Table.Row>
  <Table.Row>
    <Table.Cell>TOTAL GENERATION (kWh)</Table.Cell>
    {renderInvData('TTL_PWR')}
  </Table.Row>
  <Table.Row>
    <Table.Cell>TOTAL APPARENT POWER (KVA)</Table.Cell>
    {renderInvData('APP_PWR')}
  </Table.Row>
  <Table.Row>
    <Table.Cell>TEMPERATURE (CELCIUS)</Table.Cell>
    {renderInvData('TEMP_INV')}
  </Table.Row>
  <Table.Row>
    <Table.Cell>FREQUENCY (Hz)</Table.Cell>
    {renderInvData('FRQ')}
  </Table.Row>
</Table.Body>
</Table>
</div>


    );
}

const mapStateToProps = (state) => {
    return {plant: state.plant}
}

export default connect(mapStateToProps)(InverterTable);
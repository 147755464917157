import { GET_ICON_PATH, RESET_ICON_PATH } from "../actions/types";


const initialState = {data: null};

const iconPathReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ICON_PATH:
      return { ...state, data: payload };
    case RESET_ICON_PATH:
        return initialState;
    default:
      return state;
  }
}

export default iconPathReducer;
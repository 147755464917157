import React from 'react';
import { useRef, useState } from 'react';
import { Button } from 'semantic-ui-react';

const FileUpload = (props) => {
    const [file, setFile] = useState();
    const inputRef = useRef(null);

  const handleUploadClick = () => {
    // 👇 We redirect the click event onto the hidden input element
    inputRef.current?.click();
  };

  const handleFileChange = (e) => {
    if (!e.target.files) {
      return;
    }

    const fileObj = e.target.files && e.target.files[0];

    setFile(fileObj);

    // 🚩 do the file upload here normally...
  };

  const handleResetUpload = () => {
    inputRef.current.value = null
    setFile('')
  }

  

  return (
    <div style={{display: 'flex', height: "20vh", textAlign: 'center', alignItems: 'center', justifyContent: 'center'}}>
      <div>
      <div style={{fontSize: 32}}>{props.headingContent}:</div><br/>

      {/* 👇 Our custom button to select and upload a file */}
      <h3 style={file ? {color: 'green'} : {color: 'red'}}>{file ? `${file.name}` : 'No files selected'}</h3><br/>
      {!file ? <Button onClick={handleUploadClick}>
        Attach File
      </Button>
      :
      <>
      <Button color='red' onClick={handleResetUpload}>
        Remove File
      </Button>
      <Button color='blue' onClick={()=>{props.handleUpload(file); handleResetUpload()}}>
        Upload File
      </Button>
      </>}

      {/* 👇 Notice the `display: hidden` on the input */}
      <input
        type="file"
        ref={inputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }}
        accept={props.accept}
      />
      </div>
    </div>

  );
}

export default FileUpload;
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import FileUpload from '../components/FileUpload';
import { iconUpload } from '../actions/plantAction';

const IconUpload = (props) => {

    const { plantID, iconUpload } = props

    const handleUpload = (fileData) => {
        iconUpload(fileData, props.plantID)
    }

    return (
        <div style={{marginTop: 30, height: '100vh'}}>
            <FileUpload accept=".png" handleUpload={handleUpload} headingContent="Upload Icon"/>
            
            <div className="ui divider inverted"></div>

            </div>
    );
}

const mapStateToProps = (state) => {
    return {plantID: state.plantID.data ? state.plantID.data : null}
}

export default connect(mapStateToProps, {iconUpload})(IconUpload);
import './Header.css';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Icon, Image, Menu, Popup } from 'semantic-ui-react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import format from 'date-fns/format';
import { subDays, subMonths } from 'date-fns';
import { sendDailyReport } from '../actions/plantAction';

import logo from '../assets/images/sunindustries.png';
// import clientLogo from '../assets/images/pseudoClientImage.png';
import { toggleSidebar } from "../actions/sidebarAction";
// import Auth from './Auth';
import { logout } from '../actions/auth';
import { getImagePath } from '../actions/plantAction';

const Header = (props) => {
  
  const navigate = useNavigate();

  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [isDayOpen, setIsDayOpen] = useState(false);
  const [startDate, setStartDate] = useState(subDays(new Date(), 1));
    const {plantID, getImagePath, iconPath} = props

  useEffect(()=>{
    getImagePath({plantID})
  }, [plantID, getImagePath, iconPath])

    var showAlarms;

// let clientLogo = (plantid) => {
//   if (props.plantID && plantid === "202212004_BCM") {
//     return ["brinda.png", 400]
//   } else if (props.plantID && plantid === "202212003_SSM") {
//     return ["subburaj.png", 95]
//   } else if (props.plantID && plantid === "202212001_COP") {
//     return ["copral.png", 400]
//   } else if (props.plantID && plantid === "202212002_INF") {
//     return ["infini.png", 95]
//   } else if (props.plantID && plantid === "202212006_AD14") {
//     return ["adhve_logo.png", 95]
//   } else {
//     return 0
//   }
  
// }

  const onSidebarToggle = () => {
      
      if (props.isVisible) {
        props.toggleSidebar(false)
      } else {
        props.toggleSidebar(true)
      }

    }

  const handleLogOut = () => {
      props.logout()
    };

    const handlePlantSelection = () => {
      navigate("/plantselector")      
    }

    const handleAlarms = () => {
      navigate("/activealarms")      
    }

    const { loginTime } = props;

    // const renderLoginTime = () => {
    //   if (!props.plant.data || props.plant.data === undefined) {
    //     return null;
    //   } else {
    //     return (
    //       <Menu.Item style={{padding: 0}}>
    //       <label style={{color: 'red', fontSize: 18}}>Login Time: </label>
    //         <label style={{fontSize: 18}}>{format(new Date(loginTime), `eee PPpp`)} </label>
    //       </Menu.Item>
    //     )
    //   }
      
    // }

    const renderUpdateTime = () => {
      if (!props.plant.data || props.plant.data === undefined) {
        return null;
      } else {
        var updateTime = props.plant.data.data.mqttTime;      
        return (
          <Menu.Item style={{padding: 0}}>
            <label style={{color: 'red', fontSize: 18}}>Last Update: </label>
            <label style={{fontSize: 18}}>{format(new Date(updateTime), `eee PPpp`)} </label>
          </Menu.Item>
        )
      }      
    }

    // const handleReport = () => {
    //   navigate("/sendmail")
    // };

    const handleDayChange = (e) => {
      setIsDayOpen(!isDayOpen);
      setStartDate(e);
      props.sendDailyReport({plantID: props.plantID, email: props.email, fromDate: e})

    };
    
    const handleDayClick = (e) => {
      e.preventDefault();
      setIsDayOpen(!isDayOpen);
    };

  if (props.role !== 'superAdmin' && props.alarms) {
    showAlarms = props.alarms.filter((data)=>{
      return props.plantID.includes(data.plantID)
    }).length > 0
  } else if (props.role === 'superAdmin' && props.alarms) {
    showAlarms = props.alarms.length > 0
  } else {
    showAlarms = false;
  }

    if (props.isMobile) {

      return ((
        <div className='mobile-container'>
          {/* <div style={{display: "flex"}}>
          <Menu style={{backgroundColor: '#e4e4e4', height: 10, width: "100%"}}>
        <Menu.Item style={{padding: 0}}>
            <Image src={clientLogo(props.plantID) ? require(`../assets/images/${clientLogo(props.plantID)[0]}`) : ""} width={clientLogo(props.plantID) ? clientLogo(props.plantID)[1]*45/100 : 0}/>
            
          </Menu.Item>
          <Menu.Item style={{display: 'flex', flex: 1, justifyContent: 'center'}}>
            <p style={{textAlign: 'center'}}><span style={{fontFamily: "myFont", fontSize: 25, color: '#104782'}}>PVBEAM</span><br/><span style={{fontSize: 12, color: '#ed0b35'}}>Web Scada</span></p>
          </Menu.Item>
          <Menu.Item  position='right' style={{padding: 0}}>
            <Image src={require('../assets/images/sun-mobile.png')} width={35}/>
          </Menu.Item>

        </Menu> 
          </div> */}
          <div style={{display: "flex"}}>
          <Menu attached='top' floated compact widths={2}>
          <Menu.Menu style={{width: 100 }}>
            <div style={{padding: 0, width: '100%'}}>
              <Image src={process.env.REACT_APP_BASE_URL+iconPath} height={50} style={{objectFit: 'contain'}}/>
              </div>
          </Menu.Menu>
          <Menu.Menu style={{display: 'flex', flex: 1, justifyContent: 'center'}}>
          <Menu.Item style={{padding: 0}}>
            <p style={{textAlign: 'center'}}><span style={{fontFamily: "myFont", fontSize: 20, color: '#104782'}}>PVBEAM</span><br/><span style={{fontSize: 12, color: '#ed0b35'}}>Web Scada</span></p>
          </Menu.Item>
          </Menu.Menu>
          <Menu.Menu position='right' style={{flexDirection: 'row-reverse'}}>
            <Menu.Item style={{padding: 0}}>
            <Image src={require('../assets/images/sun-mobile.png')}/>
            </Menu.Item>
          </Menu.Menu>
        </Menu>
        </div>
        {/* ++++++++++ */}
          <nav style={{display: "flex"}}>
            <Button circular icon onClick={()=>onSidebarToggle()} style={{backgroundColor: "white"}}>
              <Icon name='list ul'/>
            </Button>
            <span style={{flex: "1 1 auto"}}></span>
            <Button circular icon onClick={()=>{setIsNavExpanded(!isNavExpanded)}} style={{backgroundColor: "white"}}>
              <Icon name='list ul'/>
            </Button>
            <div
        className={isNavExpanded ? "navigation-menu" : "navigation-menu closed"}>
            <ul>
              <li>
                <Link to="login" onClick={handleLogOut}>Logout</Link>
                {props.role === 'superAdmin' && (<Link to="plantselector" onClick={()=>{setIsNavExpanded(false)}} >Select Plant</Link>)}
              </li>
            </ul>
        </div>
          </nav>          
        </div>
      ))
    }

    return (
      <Menu vertical fluid secondary size='tiny'>
        {/* <Menu style={{backgroundColor: '#e4e4e4'}}>
        <Menu.Item style={{padding: 0}}>
            <Image src={clientLogo(props.plantID) ? iconPath : ""}  style={{minWidth:"20px", minHeight:"88px", maxWidth: "300px", maxHeight: "88px"}} />
          </Menu.Item>
          <Menu.Item style={{display: 'flex', flex: 1, justifyContent: 'center'}}>
            <p style={{textAlign: 'center'}}><span style={{fontFamily: "myFont", fontSize: 32, color: '#104782'}}>PVBEAM</span><br/><span style={{fontSize: 12, color: '#ed0b35'}}>Web Scada</span></p>
          </Menu.Item>
          <Menu.Item  position='right' style={{padding: 0}}>
            <Image src={logo} width={400}/>
          </Menu.Item>

        </Menu>           */}

      <Menu attached='top' floated compact>
          <Menu.Menu>
            <Menu.Item style={{padding: 0}}>
              <Image src={process.env.REACT_APP_BASE_URL+iconPath}/>
              </Menu.Item>
          </Menu.Menu>
          <Menu.Menu style={{display: 'flex', flex: 1, justifyContent: 'center'}}>
          <Menu.Item style={{padding: 0}}>
            <p style={{textAlign: 'center'}}><span style={{fontFamily: "myFont", fontSize: 32, color: '#104782'}}>PVBEAM</span><br/><span style={{fontSize: 12, color: '#ed0b35'}}>Web Scada</span></p>
          </Menu.Item>
          </Menu.Menu>
          <Menu.Menu position='right' style={{flexDirection: 'row-reverse'}}>
            <Menu.Item style={{padding: 0}}>
            <Image src={require('../assets/images/sunindustries.png')}/>
            </Menu.Item>
          </Menu.Menu>
        </Menu>

        <Menu>
          <Menu.Item style={{padding: 0}}>
            <Button basic fluid icon onClick={()=>onSidebarToggle()} style={{width: 60, height: '40px'}}>
              <Icon name="bars" size='large'/>
            </Button>
          </Menu.Item>
          {/* {renderLoginTime()} */}
          {renderUpdateTime()}
          <Menu.Item position="right"></Menu.Item>
          <Menu.Item style={{padding: 0}}>
            {showAlarms && <Button icon color='red' style={{width: 50, height: '40px'}} onClick={handleAlarms}>
                <Icon size='large' name="alarm"/>
            </Button>}
          </Menu.Item>
          <Menu.Item style={{padding: 0}}>
            {props.plantID ?
            <>
            <Popup content="Mail" position='top right' trigger={
              <Button icon color='blue' style={{width: 50, height: '40px'}} onClick={handleDayClick}>
              <Icon size='large' name="mail"/>
          </Button>
            }/>
            {isDayOpen && (
            <div style={{position: "absolute", right: "1%", zIndex: 999}}>
            <DatePicker selected={startDate} onChange={handleDayChange}
            popperPlacement="bottom"
            minDate={subMonths(new Date(), 2)}
            maxDate={subDays(new Date(), 1)}
            inline
            />
            </div>
          )}
          </>
            : null}          
          </Menu.Item>
          {props.selectorVisibility && (<Menu.Item style={{padding: 0}}>
            <Popup content="Plant selector" position='top right' trigger={
              <Button icon color='teal' style={{width: 50, height: '40px'}} onClick={handlePlantSelection}>
              <Icon size='large' name="sitemap"/>
          </Button>
            }/>          
          </Menu.Item>)}
          <Menu.Item style={{padding: 0}}>
          <Popup content="Logout" position='top right' trigger={
            <Button
            color='orange'
            filled='true'
            icon
            onClick={handleLogOut}
            style={{width: 50, height: '40px'}}
            >
              <Icon size='large' name="log out"/>
            </Button>
          }/>
          </Menu.Item>
        </Menu>
      </Menu>
    );
  }

export default connect(null, { toggleSidebar, logout, sendDailyReport, getImagePath })(Header);
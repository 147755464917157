import jwt from 'jwt-decode';
import backendApi from "../apis/backendApi";

const signIn = async (formValues) => {
  localStorage.clear()
  const response = await backendApi.post("/getUser", formValues);
  if (response.data.token) {
    localStorage.setItem("token", JSON.stringify(response.data.token))
    let decodedToken = await jwt(response.data.token)
    localStorage.setItem("user", JSON.stringify(decodedToken));
    
  }
  return response.data;
};

const logout = () => {
  localStorage.clear();
 
};

export default {
  signIn,
  logout,
};

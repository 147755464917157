import React from "react";
import { connect } from "react-redux";
import { Icon } from "semantic-ui-react";

const MiscCard = (props) => {

 
  const getMscData = (tagKey) => {
    if (props.plant.data) {

    
    return props.plant.data.data.msc
      .filter((ms) => {
        if (ms.tags) {
          return ms.tags[0].tagID === tagKey;
        } else {
          return null
        }
      })
      .map((data) => {
        return data.tags[0].tagValue;
      });
    }
  }

  return (
    <div className={props.isMobile ? "ui centered two stackable cards" : "ui centered four stackable cards"}>
      <div className="ui card" style={{outline: "1px solid red", border: "2px solid red", outlineOffset: "3px"}}>
        <div className="content">
          <img alt=""
            className="floated mini ui image"
            src={require("../assets/images/PR.png")}
          />
          <div
            className="right floated header"
            style={{ marginRight: 10, marginTop: 10 }}
          >
            DC
            <div className="meta extra content">
              kWh
            </div>
          </div>
          
          
          <table className="ui celled red table unstackable">
            <thead>
              <tr className="center aligned">
                <th>TODAY CUM</th>
                <th>YEARLY CUM</th>
              </tr>
            </thead>
            <tbody>
              <tr className="center aligned">
                <td data-label="TODAY CUM">{parseFloat(getMscData('DC_TKWH')).toFixed(2)}</td>
                <td data-label="YEARLY CUM">{parseFloat(getMscData('DC_CKWH')).toFixed(2)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="ui fluid card" style={{outline: "1px solid yellow", border: "2px solid yellow", outlineOffset: "3px"}}>
        <div className="content">
          <img alt=""
            className="floated mini ui image"
            src={require("../assets/images/grid.png")}
          />
          <Icon fitted name="arrow up" size="large" circular/>
          <div
            className="right floated header"
            style={{ marginRight: 10, marginTop: 10 }}
          >
            VCB
            <div className="meta extra content">
              kWh
            </div>
          </div>
          <table className="ui celled yellow table unstackable">
            <thead>
              <tr className="center aligned">
                <th>TODAY CUM</th>
                <th>YEARLY CUM</th>
              </tr>
            </thead>
            <tbody>
              <tr className="center aligned">
                <td data-label="TODAY CUM">{parseFloat(getMscData('VC_TKWH')).toFixed(2)}</td>
                <td data-label="YEARLY CUM">{parseFloat(getMscData('VC_CKWH')).toFixed(2)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="ui card" style={{outline: "1px solid orange", border: "2px solid orange", outlineOffset: "3px"}}>
        <div className="content">
          <img alt=""
            className="floated mini ui image"
            src={require("../assets/images/PR.png")}
          />
          <div
            className="right floated header"
            style={{ marginRight: 10, marginTop: 10 }}
          >
            PR
            <div className="meta extra content">
            <br />
            </div>
          </div>
          <table className="ui celled orange table unstackable">
            <thead>
              <tr className="center aligned">
                <th>TODAY AVG</th>
                <th>YEARLY AVG</th>
              </tr>
            </thead>
            <tbody>
              <tr className="center aligned">
                <td data-label="TODAY AVG">{parseFloat(getMscData('PR_TOD')).toFixed(2)}</td>
                <td data-label="YEARLY AVG">{parseFloat(getMscData('PR_CUM')).toFixed(2)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      

      <div className="ui card" style={{outline: "1px solid olive", border: "2px solid olive", outlineOffset: "3px"}}>
        <div className="content">
          <img alt=""
            className="floated mini ui image"
            src={require("../assets/images/inverter.png")}
          />
          <div
            className="right floated header"
            style={{ marginRight: 10, marginTop: 10 }}
          >
            INV
            <div className="meta extra content">
              kWh
            </div>
          </div>
          <table className="ui celled olive table unstackable">
            <thead>
              <tr className="center aligned">
                <th>TODAY CUM</th>
                <th>YEARLY CUM</th>
              </tr>
            </thead>
            <tbody>
              <tr className="center aligned">
                <td data-label="CUM">{parseFloat(getMscData('IN_TKWH')).toFixed(2)}</td>
                <td data-label="YEARLY">{parseFloat(getMscData('IN_CKWH')).toFixed(2)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="ui fluid card" style={{outline: "1px solid yellow", border: "2px solid yellow", outlineOffset: "3px"}}>
        <div className="content">
          <img alt=""
            className="floated mini ui image"
            src={require("../assets/images/grid.png")}
          />
          <Icon fitted name="arrow down" size="large" circular/>
          <div
            className="right floated header"
            style={{ marginRight: 10, marginTop: 10 }}
          >
            VCB-IMP
            <div className="meta extra content">
              kWh
            </div>
          </div>
          <table className="ui celled yellow table unstackable">
            <thead>
              <tr className="center aligned">
                <th>TODAY</th>
                <th>CUM</th>
              </tr>
            </thead>
            <tbody>
              <tr className="center aligned">
                <td data-label="TODAY">{parseFloat(getMscData('VCB_TODAY_IMP_KWH')).toFixed(2)}</td>
                <td data-label="CUM">{parseFloat(getMscData('VCB_CUMM_IMP_KWH')).toFixed(2)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="ui card" style={{outline: "1px solid green", border: "2px solid green", outlineOffset: "3px"}}>
        <div className="content">
          <img alt=""
            className="floated mini ui image"
            src={require("../assets/images/irradiance.png")}
          />
          <div
            className="right floated header"
            style={{ marginRight: 10, marginTop: 10 }}
          >
            IR
            <div className="meta extra content">
            kWh/m2
            </div>
          </div>
          <table className="ui celled green table unstackable">
            <thead>
              <tr className="center aligned">
                <th>TODAY CUM</th>
                <th>YEARLY CUM</th>
              </tr>
            </thead>
            <tbody>
              <tr className="center aligned">
                <td data-label="TODAY CUM">{parseFloat(getMscData('IR_TOD')).toFixed(2)}</td>
                <td data-label=" YEARLY CUM">{parseFloat(getMscData('IR_CUM')).toFixed(2)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="ui card" style={{outline: "1px solid blue", border: "2px solid blue", outlineOffset: "3px"}}>
        <div className="content">
          <img alt=""
            className="floated mini ui image"
            src={require("../assets/images/PAVL.png")}
          />
          <div
            className="right floated header"
            style={{ marginRight: 10, marginTop: 10 }}
          >
            PAVL
            <div className="meta extra content">
              %
            </div>
          </div>
          <table className="ui celled blue table unstackable">
            <thead>
              <tr className="center aligned">
                <th>TODAY AVG</th>
                <th>YEARLY AVG</th>
              </tr>
            </thead>
            <tbody>
              <tr className="center aligned">
                <td data-label="TODAY AVG">{parseFloat(getMscData('PAVL_TOD')).toFixed(2)}</td>
                <td data-label="YEARLY AVG">{parseFloat(getMscData('PAVL_CUM')).toFixed(2)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="ui card" style={{outline: "1px solid purple", border: "2px solid purple", outlineOffset: "3px"}}>
        <div className="content">
          <img alt=""
            className="floated mini ui image"
            src={require("../assets/images/spower.png")}
          />
          <div
            className="right floated header"
            style={{ marginRight: 10, marginTop: 10 }}
          >
            GAVL
            <div className="meta extra content">
              %
            </div>
          </div>
          <table className="ui celled purple table unstackable">
            <thead>
              <tr className="center aligned">
                <th>TODAY AVG</th>
                <th>YEARLY AVG</th>
              </tr>
            </thead>
            <tbody>
              <tr className="center aligned">
                <td data-label="AVG">{parseFloat(getMscData('GRD_AVLT')).toFixed(2)}</td>
                <td data-label="YEARLY AVG">{parseFloat(getMscData('GRD_AVLC')).toFixed(2)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { plant: state.plant, isMobile: state.isMobile.isMobile}
}

export default connect(mapStateToProps)(MiscCard);
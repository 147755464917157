import { GET_PLANT_ID, RESET_PLANT_ID } from "../actions/types";

let plantID = localStorage.getItem("plantID")


let initialState = plantID ? {data: plantID} : {data: null}

const plantReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PLANT_ID:
            return {...state, data: action.payload};
        case RESET_PLANT_ID:
            return initialState;
        default:
            return state;
    }
}

export default plantReducer;
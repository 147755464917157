

import { toast } from 'react-hot-toast';
import DataService from "../services/data.service";
import { GET_ALL_USERS, SET_MESSAGE } from './types';

export const createUser = (fileData) => {
    return (dispatch) => {
        return DataService.createUsers(fileData).then(
            (response) => {
              if (response.data.code !== 200 ) {
                dispatch({
                  type: SET_MESSAGE,
                  payload: response.data.message,
                });

                toast.error(response.data.message)

                return;
              }


              dispatch({
                type: GET_ALL_USERS,
                payload: response.data,
              });

              dispatch({
                type: SET_MESSAGE,
                payload: response.data.message,
              });

              response.data.data.forEach((user)=>{
                if (user.code === 200) {
                    toast.success(user.message)
                } else if (user.code === 400) {
                    toast.error(user.message)
                }
              })

              return Promise.resolve();
            },
            (error) => {
              const message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
       
              dispatch({
                type: SET_MESSAGE,
                payload: message,
              });
        
              return Promise.reject();
            }
          );
    }
}

export const getAllUsers = () => {
    return (dispatch) => {
        return DataService.getAllUsers().then(
            (response) => {
              if (response.data.code !== 200 ) {
                // dispatch({
                //   type: SET_MESSAGE,
                //   payload: response.data.message,
                // });

                return;
              }


              dispatch({
                type: GET_ALL_USERS,
                payload: response.data,
              });

              // dispatch({
              //   type: SET_MESSAGE,
              //   payload: response.data.message,
              // });

              return Promise.resolve();
            },
            (error) => {
              const message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
       
              dispatch({
                type: SET_MESSAGE,
                payload: message,
              });
        
              return Promise.reject();
            }
          );
    }
}


export const updateUser = (userData) => {
  return (dispatch) => {
      return DataService.updateUser(userData).then(
          (response) => {
            if (response.data.code !== 200 ) {
              dispatch({
                type: SET_MESSAGE,
                payload: response.data.message,
              });

              toast.error(response.data.message)

              return;
            }

            dispatch({
              type: SET_MESSAGE,
              payload: response.data.message,
            });

            toast.success(response.data.data.isActive ? "User Activated" : "User Deactivated")

            return Promise.resolve();
          },
          (error) => {
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
     
            dispatch({
              type: SET_MESSAGE,
              payload: message,
            });
      
            return Promise.reject();
          }
        );
  }
}
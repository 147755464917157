import {PLANTSELECTOR_VISIBLE, RESET_VISIBLE_PLANTSELECTOR } from "../actions/types";

let initialState = localStorage.getItem('plantSelectorVisibility') ? 

{
    isVisible: true
} :

{
    isVisible: false
}


const plantSelectorVisibility = (state = initialState, action) => {
    switch (action.type) {
        case PLANTSELECTOR_VISIBLE:
            return {...state, isVisible: action.payload};
        case RESET_VISIBLE_PLANTSELECTOR:
            return initialState;
        default:
            return state;
    }
}

export default plantSelectorVisibility;
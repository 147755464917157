import './ForgotPassword.css'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-hot-toast';
import backendApi from "../apis/backendApi";


const ForgotPassword = () => {

    const navigate = useNavigate();

    const [email, setEmail] = useState("")

  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")

  useEffect(() => {
      if (localStorage.getItem("token")) {
        navigate("/login")
      }
  
  }, [navigate])
  

  const forgotPasswordHandler = async (e) => {
    e.preventDefault()

    
    try {
      
      const { data } = await backendApi.post(
        "/forgotpassword",
        {  email },
      );

      if (data.code !==200) {
        toast.error(data.message)
      } else {
        toast.success(data.message + ".  Check Mail")
      }


    
    } catch (error) {
      console.log(error.message)
      toast.error(error.response.data.error);
      setEmail("")
      setTimeout(() => {
        setError("");
      }, 5000);
    }
  }

  return (
    <div>
      <div className="bold-line"></div>
      <div className="password-container">
        <form onSubmit={forgotPasswordHandler} className="window"> 
          <div className="overlay"></div>
          <div className="password-content">
            {error && {error}}
            {success &&{success}}
            <div className="welcome">Forgot Password</div>
            <div className="subtitle">
                Please enter your email address.
            </div>
            <div className="input-fields">
              <input
                type="email"
                placeholder="Email"
                className="input-line full-width"
                required
                value= {email}
                onChange={(e)=> setEmail(e.target.value)}
                style={{paddingLeft: 10}}            
              ></input>
        
             
            </div>
            <div>
              <button  className="ghost-round full-width" >Send</button>
            </div>
                
           
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
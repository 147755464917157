import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Dropdown, Icon, Image, Menu, Sidebar, Label } from "semantic-ui-react";
import { toast } from 'react-hot-toast';

import Header from "./Header";
import { toggleSidebar } from "../actions/sidebarAction";
import { getPlantMqttIntermediate, getAllActiveAlarms } from "../actions/plantAction";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PLANTSELECTOR_VISIBLE } from "../actions/types";

// import { ACDB_TABLE_IS_SHOWN } from "../actions/types";

const Layout = (props) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  // const { children, isVisible, isLoggedIn, getPlantMqttIntermediate, plantID, role, plant, acdbIsVisible } = props;
  const { children, isVisible, isLoggedIn, getPlantMqttIntermediate, plantID, role, getAllActiveAlarms, plant} = props;

  useEffect(() => {

    var timer;

    if (isLoggedIn && !Array.isArray(plantID)) {
      getPlantMqttIntermediate({ plantID: plantID });
    }

    if (role === 'superAdmin') {

      localStorage.setItem('plantSelectorVisibility', 1)

      dispatch({
          type: PLANTSELECTOR_VISIBLE,
          payload: true
      })
  }

    if (role === 'superAdmin' && !plantID && location.pathname !== "/plants" && location.pathname !== "/plantselector") {
      toast.error("Plant yet to be selected")
      navigate("/users")    
  }

    if (isLoggedIn && !Array.isArray(plantID)) {

      getAllActiveAlarms();

    timer = setInterval(() => {
      getPlantMqttIntermediate({ plantID: plantID });
    }, 60000);

  }

    if (!isLoggedIn) {
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  }, [getPlantMqttIntermediate, isLoggedIn, plantID, getAllActiveAlarms, dispatch, navigate]);

  // Below code block should be included if table data is to be shown
  //  useEffect(()=>{
  //   if (isLoggedIn && plant.data) {
  //     plant.data.data.transformer.map((trf)=>{
  //       if (trf.acdb) {
  //          dispatch({
  //           type: ACDB_TABLE_IS_SHOWN,
  //           payload: true
  //         })
  //       } else {
  //         dispatch({
  //           type: ACDB_TABLE_IS_SHOWN,
  //           payload: false
  //         })      
  //       }
  //     })
  //   }
    
  // }, [isLoggedIn, plant])

  return (
    <Sidebar.Pushable>
      <Sidebar
        as={Menu}
        animation="push"
        icon="labeled"
        vertical
        // inverted
        onHide={() => props.toggleSidebar(false)}
        // vertical
        visible={isVisible}
        width="thin"
      >
        {plant.data && <Menu.Item style={{marginBottom: 40}}>
          <Label as='a' color='orange'>
            {plant.data.data.plantName}
          </Label>
        </Menu.Item>}
        <Menu.Item
          as={Link}
          name="dashboard"
          to="dashboard"
          onClick={() => props.toggleSidebar(false)}
        >
          <Icon name="dashboard" />
          Dashboard
        </Menu.Item>
        <Menu.Item
          as={Link}
          name="sld"
          to="sld"
          onClick={() => props.toggleSidebar(false)}
        >
          <Icon name="sort amount up" />
          SLD
        </Menu.Item>
        {/* {role === 'superAdmin' ? (<div>
          {acdbIsVisible && <Menu.Item
          as={Link}
          name="acdbTable"
          to="acdbTable"
          onClick={() => props.toggleSidebar(false)}
        >
          <Image
            src={require("../assets/images/acdbIcon.png")}
            centered
            style={{ marginBottom: 10, backgroundColor: "white" }}
          />
          ACDB Table
        </Menu.Item>}
        <Menu.Item
          as={Link}
          name="vcbTable"
          to="vcbTable"
          onClick={() => props.toggleSidebar(false)}
        >
          <Image
            src={require("../assets/images/vcbIcon.png")}
            centered
            style={{ marginBottom: 10, backgroundColor: "white" }}
          />
          VCB Table
        </Menu.Item>
        
        </div>)
        :
        null} */}
        
          <Menu.Item>
          <Icon name="chart line" />
          
          <Dropdown text="Trends" style={{paddingBottom: 10}}>
          <Dropdown.Menu text="Trends">
            <Dropdown.Item
            as={Link}
            name="chart"
            to="chart/1"
            onClick={() => props.toggleSidebar(false)}>
            DPG
            </Dropdown.Item>
            <Dropdown.Item
            as={Link}
            name="chart"
            to="chart/2"
            onClick={() => props.toggleSidebar(false)}>
              INV
            </Dropdown.Item>
            <Dropdown.Item
            as={Link}
            name="chart"
            to="chart/3"
            onClick={() => props.toggleSidebar(false)}>
              TRF
            </Dropdown.Item>
            <Dropdown.Item
            as={Link}
            name="chart"
            to="chart/4"
            onClick={() => props.toggleSidebar(false)}>
              30-DAY
            </Dropdown.Item>
            {role === "superAdmin" && <Dropdown.Item
            as={Link}
            name="chart"
            to="chart/5"
            onClick={() => props.toggleSidebar(false)}>
              COUNTER
            </Dropdown.Item>}
          </Dropdown.Menu>
          </Dropdown>
          </Menu.Item>

        <Menu.Item as={Link} name='allalarms' to='allalarms' onClick={() => props.toggleSidebar(false)}>
        <Icon name="alarm" />
          Alarms
        </Menu.Item>

        <Menu.Item as={Link} name='reports' to='reports' onClick={() => props.toggleSidebar(false)}>
        <Icon name="envelope outline" />
          Monthly Report
        </Menu.Item>

          {role === 'superAdmin' && !props.isMobile && (<><Menu.Item as={Link} name='users' to='users' onClick={() => props.toggleSidebar(false)}>
          <Icon name="users" />
          User Management
        </Menu.Item>
        <Menu.Item as={Link} name='plants' to='plants' onClick={() => props.toggleSidebar(false)}>
        <Icon name="cubes" />
          Plant Management
        </Menu.Item>
        </>)}
        {!props.isMobile && <Menu.Item as={Link} name='iconupload' to='iconupload' onClick={() => props.toggleSidebar(false)}>
          <Icon name="upload" />
          Icon Upload
        </Menu.Item>}

     
      </Sidebar>

      <Sidebar.Pusher dimmed={isVisible} style={{height: '100vh', overflow: 'auto'}}>
        {isLoggedIn && <Header {...props} />}
        {children}
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    isVisible: state.sidebar.isVisible,
    plant: state.plant ? state.plant : null,
    loginTime: state.auth.user ? state.auth.user.loginTime : null,
    role: state.auth.user ? state.auth.user.payload.role : null,
    plantID: state.plantID ? state.plantID.data : null,
    acdbIsVisible: state.tableShown.isVisible,
    message: state.message,
    email: state.auth.user ? state.auth.user.payload.email : null,
    associatedPlants: state.auth.user ? state.auth.user.payload.associatedPlants : null,
    selectorVisibility: state.selectorVisibility ? state.selectorVisibility.isVisible : null,
    alarms: state.activeAlarms.data ? state.activeAlarms.data : null,
    isMobile: state.isMobile.isMobile,
    iconPath: state.iconPath.data ? state.iconPath.data : null 

  };
};

export default connect(mapStateToProps, {
  toggleSidebar,
  getPlantMqttIntermediate,
  getAllActiveAlarms,
})(Layout);

import React, { useEffect } from 'react';
import './Login.css'

import { Image } from 'semantic-ui-react';
import { Form, Field } from 'react-final-form'
import { Link, useNavigate } from 'react-router-dom';
// import email from "../assets/images/email.jpg";
// import pass from "../assets/images/pass.png";
import logo from"../assets/images/logo.png";
import { connect } from 'react-redux';
import { signIn } from '../actions/auth';

const Login = (props) => {

  const navigate = useNavigate();

  const { signIn, isLoggedIn, role } = props

  useEffect(()=>{

    if (isLoggedIn && role === 'superAdmin') {
      navigate("/users")
    }

    if (isLoggedIn && role === 'user') {
      navigate("/plantselector")
    }

    // if (isLoggedIn) {
    //   navigate("/plantselector")
    // }


  }, [isLoggedIn, role, navigate])

    const renderError= ({ error, touched }) => {

        if (touched && error) {
          return (
            <div className='ui error message'>
              <div className='header'>{error}</div>
            </div>
            
          );
        }
    
      }
      
    const renderInput = ({ input, placeholder, meta, classInput }) => {
        const className=`field ${meta.error && meta.touched ? 'error': ''} second-input`
        return (
          <div className={className}>
            {/* <div className="ui big teal label" >{label}</div> */}
            {/* <input size='large' placeholder={placeholder} type={type} autoComplete="off" />
            {renderError(meta)} */}
            
            <input size='large' {...input} placeholder={placeholder} type={input.type} autoComplete="off" className={classInput}/>
            {renderError(meta)}
          </div>
        );
    
      }

      const validate = (formValues) => {
        const errors = {};
      
        if (!formValues.email) {
          errors.email = 'Please enter valid email'
        }
      
        if (!formValues.password) {
          errors.password = 'Password field should not be empty'
        }
      
        return errors;
      }

    const onSubmit = async (formValues) => {

        await signIn(formValues).then(()=>{
          if (role === 'user') {
            navigate("/plantselector");
          }

          if (role === 'superAdmin') {
            navigate("/users")
          }

          // navigate("/plantselector")
          
        })
      }


    return (
        <div className="main">
            <div className={props.isMobile ? "" : "sub-main"}>
                <div>
                    <h1><Image src={logo} /></h1>
                    <Form 
                    onSubmit={onSubmit}
                    validate={validate}
                    render={({handleSubmit, form, submitting, pristine, values})=>(
                        <form onSubmit={handleSubmit}>
                            <Field name="email" component={renderInput} placeholder="Email" type="text" classInput="name"/>

                            <Field name="password" component={renderInput} placeholder="Password" type="password" classInput="name"/>
                    
                    {/* <div className="second-input">
                        <img src={pass} alt="pass" className="email" />
                        <input type="password" placeholder="Password" className="name" />
                    </div> */}
                    <div className="login-div">
                        <button className='login-button' type="submit" disabled={submitting}>Login</button>
                    </div>
                    </form>                        
                    )}
                    />

                    <p className="login-link">
                        <Link to="/forgotpassword">Forgot password ?</Link>
                    </p>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return { message: state.message, isLoggedIn: state.auth.isLoggedIn, role: state.auth.user ? state.auth.user.payload.role : null, isMobile: state.isMobile.isMobile }
}


export default connect(mapStateToProps, { signIn } )(Login);
import { GET_ALL_USERS, RESET_ALL_USERS } from "../actions/types";

const initialState = {};

const userReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_USERS:
      return { ...state, data: payload };
    case RESET_ALL_USERS:
      return initialState;
    default:
      return state;
  }
}

export default userReducer;
import backendApi from "../apis/backendApi";
import authHeader from "./auth-header";
import store from "../store";
import { logout } from "../actions/auth";

const {dispatch} = store;

backendApi.interceptors.response.use(response => {
  if (response.data?.auth === false) {
    dispatch(logout())
  }
  return response
})

const getPlantMqttIntermediate = async (plantID) => {
    return await backendApi.post("/mergeMqttWithPlant", plantID, {headers: authHeader()});
  };

  const getAllPlants = async () => {
    return await backendApi.get("/getallplants", {headers: authHeader()})
  }

  const getTrendData = async (trendInfo) => {
    return await backendApi.post("/mergeMqttWithPlantWithRange", trendInfo, {headers: authHeader()})
  }

  const getDailyReport = async (info) => {
    return await backendApi.post("/dailyReportGenerate", info, {headers: authHeader()})
    // return await backendApi.post("/pdfGenerate", info, {headers: authHeader()})
  }

  // const getMonthlyReport = async (info) => {
  //   return await backendApi.post("/monthlyReportGenerate", info, {headers: authHeader()})
  // }

  const getMonthlyReport = async (data) => {
    
    return await backendApi.post("/getMonthlyReport", data, {headers: authHeader()})

  }

   const getAlarms = async () => {
    return await backendApi.get("/getAllActiveAlarms", {headers: authHeader()})
  }

  const getAlarmsInRange = async (info) => {
    return await backendApi.post("/getAlarmsInRange", info, {headers: authHeader()})
  }

  const createUsers = async (data) => {
    
    const formData = new FormData();

    formData.append("file", data)

    return await backendApi.post("/saveOrDeleteUser", formData, {headers: authHeader()})
  }

  const getAllUsers = async () => {
    
    return await backendApi.get("/getUsers", {headers: authHeader()})
  }

  const updateUser = async (userData) => {

    return await backendApi.post("/updateUserData", userData, {headers: authHeader()})
  }

  const createPlant = async (data) => {
    
    const formData = new FormData();

    formData.append("file", data)

    return await backendApi.post("/excelToJson", formData, {headers: authHeader()})
  }

  const updatePlant = async (plantData) => {

    return await backendApi.post("/updatePlant", plantData, {headers: authHeader()})
  }

  const uploadIcon = async (file, plantID) => {

    let newFileName = plantID + ".png"
    
    const formData = new FormData();

    formData.append("file", file, newFileName)
    formData.append("plantID", plantID)

    return await backendApi.post("/saveImage", formData, {headers: authHeader()})
  }

  const getIconPath = async (plantID) => {
    return await backendApi.post("/getIconPath", plantID, {headers: authHeader()})
  }

  

  export default {
    getPlantMqttIntermediate,
    getAllPlants,
    getTrendData,
    getDailyReport,
    getAlarms,
    createUsers,
    getAllUsers,
    updateUser,
    createPlant,
    updatePlant,
    getMonthlyReport,
    uploadIcon,
    getAlarmsInRange,
    getIconPath
  }
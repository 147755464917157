import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import FileUpload from '../components/FileUpload';
import { createPlant, getAllPlants, updatePlant } from '../actions/plantAction';
import { Checkbox } from 'semantic-ui-react';

const PlantManagement = (props) => {

    const [file, setFile] = useState();
    const inputRef = useRef(null);

    const { createPlant, plants, getAllPlants, updatePlant, message} = props

    useEffect(()=>{

        getAllPlants()
        
    }, [getAllPlants, message])

    const handleUpload = (fileData) => {
        createPlant(fileData)
        getAllPlants()
    }

    const handleCheckBoxClick = (plantData) => {
        updatePlant(plantData).then(()=>getAllPlants())
        
    }

    const handleUploadClick = () => {
        // 👇 We redirect the click event onto the hidden input element
        inputRef.current?.click();
      };

      const handleFileChange = (e) => {
        if (!e.target.files) {
          return;
        }



        const fileObj = e.target.files && e.target.files[0];
    
        setFile(fileObj);
   
        // 🚩 do the file upload here normally...
      };

        const renderTableBody = () => {
            if (plants) {
                return plants.map((plant, index)=>{
                    return (
                        <tr key={index}>
                            <td>{plant.plantName}</td>
                            <td>
                                <Checkbox toggle checked={plant.isActive} label={plant.isActive ? "Deactivate Plant" : "Activate Plant"} onClick={()=>handleCheckBoxClick({plantID: plant.plantID, isActive: !plant.isActive})}/>
                            </td>
                            {/* <td>
                                <Button color='blue' onClick={handleUploadClick}>Upload Icon Image</Button>
                                <input
                                type="file"
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                                accept=".png"
                            />
                            <input ref={inputRef} type="hidden" name="plant" id="plant" value={plant.plantID} onChange={(e)=>setPlantID(e.target.value)} />
                            
                            </td> */}
                        </tr>
                    )
                
                
            })
            } else {
                
                return null
            }
            
        } 

    return (
        <div style={{height: '100vh'}}>
            <FileUpload accept=".xlsx" handleUpload={handleUpload} headingContent="Upload plant creation excel file" />
            
               <div className="ui divider inverted"></div>
                

            <div style={{marginTop: 20}}>
            <h3 style={{textAlign: "center"}}>Plant List</h3>
            {plants ? 
            <table className="ui striped fixed compact table">
            <thead>
                <tr>
                    <th>Plant Name</th>
                    <th>Activate/Deactivate</th>
                </tr>
            </thead>
            <tbody>
                {renderTableBody()}
            </tbody>
        </table>
        :
        <h4 style={{textAlign: "center"}}>Plant List yet to populate</h4>}
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {plants: state.plants ? state.plants.data : null, message: state.message}
}

export default connect(mapStateToProps, {createPlant, getAllPlants, updatePlant})(PlantManagement);
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { PLANTSELECTOR_VISIBLE } from '../actions/types';
import Modal from '../components/Modal';
import { getAllPlants } from '../actions/plantAction';
import { getPlantMqttIntermediate } from '../actions/plantAction';
import { selectPlant } from '../actions/plantAction';
import MapComponent from '../components/MapComponent';

const PlantSelector = (props) => {

    const { payload, getPlantMqttIntermediate, getAllPlants, plantIDs, plant } = props

    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    useEffect(()=>{

        if (payload.role === 'user' && payload.associatedPlants.length === 1) {
            
            getPlantMqttIntermediate({plantID: payload.associatedPlants[0]});
            // dispatch({
            //     type: GET_PLANT_ID,
            //     payload: payload.associatedPlants[0]
            // })
            props.selectPlant(payload.associatedPlants[0])

            navigate("/dashboard")
            return;
        }

        
        // if (payload.role === 'superAdmin') {
        //     getAllPlants()
            
        // }

        getAllPlants()

    }, [getAllPlants, getPlantMqttIntermediate, navigate, dispatch, plant, payload.associatedPlants, payload.role])

    useEffect(()=>{


        if (payload.role === 'user' && payload.associatedPlants.length > 1 && Array.isArray(plantIDs)) {

            localStorage.setItem('plantSelectorVisibility', 1)

            dispatch({
                type: PLANTSELECTOR_VISIBLE,
                payload: true
            })
        }

        // if (payload.role === 'superAdmin') {

        //     localStorage.setItem('plantSelectorVisibility', 1)

        //     dispatch({
        //         type: PLANTSELECTOR_VISIBLE,
        //         payload: true
        //     })
        // }
    }, [dispatch, payload.role, payload.associatedPlants, plantIDs])


    if (!plantIDs) {
        return <div>Loading...</div>
    }


    const handleClick = (plantID) => {
        props.getPlantMqttIntermediate({plantID: plantID});
        props.selectPlant(plantID)
    }

    if (payload.role === 'user' && payload.associatedPlants.length > 1 && Array.isArray(plantIDs)) {

        // dispatch({
        //     type: PLANTSELECTOR_VISIBLE,
        //     payload: true
        // })

        var renderArray;

        const filterArray = (plantIDs, associatedPlants) => {
            const filtered = plantIDs.filter(el => {
               return associatedPlants.includes(el.plantID)
            });
            return filtered;
         };

         renderArray = filterArray(props.plantIDs, payload.associatedPlants)

    }

    if (payload.role === 'superAdmin') {

        renderArray = props.plantIDs
        // dispatch({
        //     type: PLANTSELECTOR_VISIBLE,
        //     payload: true
        // })
    }
    

    const content = 
    
    // Array.isArray(props.plantIDs) && props.plantIDs.length > 1 ? renderArray.map((plant, index)=>{
    //     if (plant.isActive) {
    //         return (
    //             <ul key={index}>
    //                 <li>
    //                 <Link to="dashboard" onClick={() => handleClick(plant.plantID)}>{plant.plantName}</Link>
    //                 </li>
    //             </ul>
                
    //         )
    //     } else {
    //         return null
    //     }
            
    //     })
    //     :
    //     null

    Array.isArray(props.plantIDs) && props.plantIDs.length > 1 ? 
    <MapComponent plant={renderArray} handleClick={handleClick} />
        :
        null

return (
    <div>
        <Modal plantIDs={props.plantIDs} content={content}/>
    </div>
    );
}

const mapStateToProps = (state) => {
    return {payload: state.auth.user.payload, plantIDs: state.plants.data ? state.plants.data : null, plant: state.plant.data ? state.plant.data : null};
}

export default connect(mapStateToProps, { getAllPlants, selectPlant, getPlantMqttIntermediate })(PlantSelector);
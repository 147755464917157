import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import jwt from 'jwt-decode';
import backendApi from "../apis/backendApi";
import authHeader from '../services/auth-header';

const DeleteRequest = () => {

    const params = useParams();
    const navigate = useNavigate();

    const [isReadOnly, setIsReadOnly] = useState(false);
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const [email, setEmail] = useState("")
    const [visible, setVisible] = useState(true);

    useEffect(()=>{
        extract()
    }, [])

    const extract = async () => {
        try {
            const decoded = jwt(params.requestToken)
            setIsReadOnly(true);
            setError("");
            setEmail(decoded.payload.email)
            setVisible(true)
            setMessage("")
        } catch (error) {
            setError(error)
            setIsReadOnly(false)
            setEmail("")
            setVisible(true)
            setMessage("")
        }
    }

    const sendLink = async () => {
        try {
            const reqBody = email.trim();
            if (reqBody === "") {
                setMessage("Please fill in the mail ID")
                return
            }
            const response = await backendApi.post(`/sendrequestdeletelink`, {email})
            if (response.data.code === 200) {
                setIsReadOnly(true);
                setVisible(false);
                setMessage("Delete request link has been sent to your mailID.  Please click on the link in the mail to raise delete request");
            } else {
                setIsReadOnly(false);
                setMessage(response.data.message);
                setVisible(true);
            }

        } catch (error) {
            setIsReadOnly(false)
            setMessage(error.message)
            setVisible(true)
        }
    }

    const requestDelete = async () => {
        try {

            const headers = {Authorization: "Bearer " + params.requestToken}
            const response = await backendApi.delete(`/requestdelete/${params.requestToken}`, {headers})
            if (response.data.code === 200) {
                setVisible(false);
                setMessage("Delete request has been raised.  Your account will be deleted within 60 days from now.")
            } else {
                setVisible(true);
                setMessage(response.data.message)
                setTimeout(()=>{
                    setMessage("");
                    setIsReadOnly(false);
                    setEmail("");
                    navigate("/requestdelete")
                }, 5000)
            }
            
        } catch (error) {
            setVisible(true);
            setMessage(error.message);
        }
    }

    
  return (
    <div className='ui container'>
        <div className='ui raised segment'>
            <div className='ui center aligned huge header'>Delete Request</div>
        </div>
        {!isReadOnly ? 
        <div className='ui header'>
            Please enter the email for which the account needs to be deleted.
        </div>
        :
        <div className='ui header'>
            On submit, request will be raised to delete your account.
        </div>}
        <div className='ui labeled input'>
            <div className='ui red horizontal label'>User Name</div>
            <input type='email' value={email} onChange={(e)=>{setEmail(e.target.value)}} readOnly={isReadOnly} />
        </div>
        <div style={{paddingTop: 10}}>
            {visible ?
            !isReadOnly ?
            <button className='ui primary button' onClick={sendLink}>Send</button>
            :
            <button className='ui secondary button' onClick={requestDelete}>Submit</button>
        :
        null}
        {message && <div className='ui large text'>{message}</div>}
        </div>            
    </div>
  )
}

export default DeleteRequest;
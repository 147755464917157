import { ACDB_TABLE_IS_SHOWN, ALARMS_IN_RANGE, GET_ALL_ACTIVE_ALARMS, GET_ALL_PLANTS, GET_ICON_PATH, GET_PLANT_ID, GET_PLANT_MQTT_INTERMEDIATE, GET_REPORT, PLANT_MQTT_WITH_RANGE, RESET_ICON_PATH, RESET_MQTT_WITH_RANGE, SET_MESSAGE } from "./types";

import { toast } from 'react-hot-toast';
import format from 'date-fns/format'
import DataService from "../services/data.service";

export const getPlantMqttIntermediate = (plantID) => {
    return (dispatch) => {
        return DataService.getPlantMqttIntermediate(plantID).then(
            (response) => {
              if (response.data.code !== 200 ) {
                dispatch({
                  type: SET_MESSAGE,
                  payload: response.data.message,
                });

                return;
              }


              dispatch({
                type: GET_PLANT_MQTT_INTERMEDIATE,
                payload: response.data,
              });

              dispatch({
                type: SET_MESSAGE,
                payload: response.data.message,
              });
        
              return Promise.resolve();
            },
            (error) => {
              const message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
       
              dispatch({
                type: SET_MESSAGE,
                payload: message,
              });
        
              return Promise.reject();
            }
          );
    }
}

export const getAllPlants = () => {
  return (dispatch) => {
    return DataService.getAllPlants().then(
      (response) => {
        if (response.data.status !== 200 ) {
          // dispatch({
          //   type: SET_MESSAGE,
          //   payload: response.data.message,
          // });

          return;
        }

        dispatch({
          type: GET_ALL_PLANTS,
          payload: response.data.data,
        });
        

        // dispatch({
        //   type: SET_MESSAGE,
        //   payload: response.data.message,
        // });

        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
 
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  }
}

export const selectPlant = (plantID) => {
  localStorage.setItem("plantID", plantID)
  return (dispatch) => {
    dispatch({
      type: GET_PLANT_ID,
      payload: plantID
    });
  }
}

export const setAcdbVisibility = (isVisible) => {
  return (dispatch) => {
    dispatch({
      type: ACDB_TABLE_IS_SHOWN,
      payload: isVisible
    })
  }
}

export const getDataForTrends = (trendInfo) => {
  return (dispatch) => {
    dispatch({
      type: RESET_MQTT_WITH_RANGE
    })
    return DataService.getTrendData(trendInfo).then((response)=>{
      if (response.data.code !== 200 ) {
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });

        dispatch({
          type: PLANT_MQTT_WITH_RANGE,
          payload: []
        });

        toast.error(response.data.message)

        return;
      }

      dispatch({
        type: PLANT_MQTT_WITH_RANGE,
        payload: response.data.data,
      });
      

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();      
    })
  }
}

export const getAllActiveAlarms = () => {
  return (dispatch) => {
    return DataService.getAlarms().then(
      (response) => {
        if (response.data.code !== 200 ) {
          dispatch({
            type: SET_MESSAGE,
            payload: response.data.message,
          });

          return;
        }

        dispatch({
          type: GET_ALL_ACTIVE_ALARMS,
          payload: response.data.data,
        });
        

        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });

        return Promise.resolve(response);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
 
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject(error);
      }
    );
  }
}

export const getAllAlarmsInRange = (info) => {
  return (dispatch) => {
    return DataService.getAlarmsInRange(info).then(
      (response) => {
        if (response.data.code !== 200 ) {
          dispatch({
            type: SET_MESSAGE,
            payload: response.data.message,
          });

          dispatch({
            type: ALARMS_IN_RANGE,
            payload: null,
          });

          toast.error(response.data.message)

          return;
        }

        dispatch({
          type: ALARMS_IN_RANGE,
          payload: response.data.data,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });

        toast.success(response.data.message)

        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
 
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        dispatch({
          type: ALARMS_IN_RANGE,
          payload: null,
        });
  
        return Promise.reject();
      }
    );
  }
}

export const createPlant = (fileData) => {
  return (dispatch) => {
      return DataService.createPlant(fileData).then(
          (response) => {
             if (response.data.code !== 200 ) {
              dispatch({
                type: SET_MESSAGE,
                payload: response.data.message,
              });

              toast.error(response.data.message)

              return;
            }

            dispatch({
              type: SET_MESSAGE,
              payload: response.data.message,
            });

            toast.success(response.data.message)

            return Promise.resolve();
          },
          (error) => {
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
     
            dispatch({
              type: SET_MESSAGE,
              payload: message,
            });
      
            return Promise.reject();
          }
        );
  }
}

export const updatePlant = (plantData) => {
  return (dispatch) => {
      return DataService.updatePlant(plantData).then(
          (response) => {
            if (response.data.code !== 200 ) {
              dispatch({
                type: SET_MESSAGE,
                payload: response.data.message,
              });

              toast.error(response.data.message)

              return;
            }

            dispatch({
              type: SET_MESSAGE,
              payload: response.data.message,
            });

            toast.success(response.data.data.isActive ? "Plant Activated" : "Plant Deactivated")

            return Promise.resolve();
          },
          (error) => {
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
     
            dispatch({
              type: SET_MESSAGE,
              payload: message,
            });
      
            return Promise.reject();
          }
        );
  }
}

export const sendDailyReport = (info) => {
  toast.success(`Request to send daily report for ${format(info.fromDate, "dd-MM-yyyy")} is accepted`)
  return (dispatch) => {
    return DataService.getDailyReport(info).then((response)=>{
      if (response.data.code !== 200 ) {
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.data,
        });

        toast.error(response.data.data)

        return;
      }

      // dispatch({
      //   type: GET_REPORT,
      //   payload: response.data.data,
      // });
      

      dispatch({
        type: SET_MESSAGE,
        payload: response.message,
      });

      toast.success(response.data.message)


      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();      
    })
  }
}


export const getMonthlyReport = (info) => {
  return (dispatch) => {
    return DataService.getMonthlyReport(info).then((response)=>{
      if (response.data.code !== 200 ) {
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.data,
        });

        toast.error(response.data.data)

        return;
      }

      dispatch({
        type: GET_REPORT,
        payload: response.data.data,
      });
      

      dispatch({
        type: SET_MESSAGE,
        payload: response.message,
      });

      toast.success(response.data.message)


      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();      
    })
  }
}

export const iconUpload = (file, newFileName) => {
  return (dispatch) => {
    return DataService.uploadIcon(file, newFileName).then((response)=>{
      if (response.data.code !== 200) {
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });

        toast.error(response.data.message)

        return;
      
      }

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message
        
      })

      dispatch({
        type: GET_ICON_PATH,
        payload: response.data.data.staticPath
      })

     
      toast.success(response.data.message)

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });


      return Promise.reject();      
    })
  }
}

export const getImagePath = (plantID) => {
  return (dispatch) => {
    return DataService.getIconPath(plantID).then((response)=>{
      if (response.data.code !== 200) {
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        })
        dispatch({
          type: RESET_ICON_PATH
        })

        // toast.error(response.data.message)

        return;
      
      }
      
      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message
        
      })
      dispatch({
        type: GET_ICON_PATH,
        payload: response.data.data[0].staticPath
        
      })

      // toast.success(response.data.message)

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();      
    })
  }
}
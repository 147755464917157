import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import FileUpload from '../components/FileUpload';
import { createUser, getAllUsers, updateUser } from '../actions/userAction';
import { Checkbox } from 'semantic-ui-react';

const UserManagement = (props) => {

    const { createUser, users, getAllUsers, updateUser, message} = props

    useEffect(()=>{

        getAllUsers()
        
    }, [getAllUsers, message])


    const handleUpload = (fileData) => {
        createUser(fileData)
        getAllUsers()
    }

    const handleCheckBoxClick = (userData) => {
        updateUser(userData)
        getAllUsers()
    }

        const renderTableBody = () => {
            return users.map((user, index)=>{
                if (users) {
                    return (
                        <tr key={index}>
                            <td>{user.userName}</td>
                            <td>{user.mobile}</td>
                            <td>{user.email}</td>
                            <td>{user.associatedPlants}</td>
                            <td>
                                <Checkbox toggle checked={user.isActive} onClick={()=>handleCheckBoxClick({email: user.email, isActive: !user.isActive})} label={user.isActive ? "Deactivate User" : "Activate User"}/>
                            </td>
                        </tr>
                    )
                } else {
                    return null
                }
                
            })
        } 

    return (
        <div style={{height: '100vh'}}>
            <FileUpload accept=".csv" handleUpload={handleUpload} headingContent="Upload user creation csv file"/>
                       
               <div className="ui divider inverted"></div>
               
            <div style={{marginTop: 20}}>
            <h3 style={{textAlign: "center"}}>User List</h3>
            {users ? 
            <table className="ui striped fixed compact table">
            <thead>
                <tr>
                    <th>User Name</th>
                    <th>Mobile</th>
                    <th>Email</th>
                    <th>Associated Plants</th>
                    <th>Activate/Deactivate</th>
                </tr>
            </thead>
            <tbody>
                {renderTableBody()}
            </tbody>
        </table>
        :
        <h4 style={{textAlign: "center"}}>User List yet to populate</h4>}
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {users: state.users.data ? state.users.data.data : null, message: state.message}
}

export default connect(mapStateToProps, { createUser, getAllUsers, updateUser } )(UserManagement);
import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import { subDays, subMonths } from 'date-fns';
import format from 'date-fns/format';

import "react-datepicker/dist/react-datepicker.css";
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { sendDailyReport } from '../actions/plantAction';

import Modal from '../components/Modal';

const MailPage = (props) => {

    const [startDate, setStartDate] = useState(subDays(new Date(), 1));
    const [isDayOpen, setIsDayOpen] = useState(false);
    // const [isMonthOpen, setIsMonthOpen] = useState(false);

    const handleDayChange = (e) => {
        setIsDayOpen(!isDayOpen);
        setStartDate(e);
        props.sendDailyReport({plantID: props.plantID, email: props.email, fromDate: e})
  
      };
      // const handleMonthChange = (e) => {
      //   setIsMonthOpen(!isMonthOpen);
      //   setStartDate(e);
      //   props.sendMonthlyReport({plantID: props.plantID, email: props.email, startDate: e})
  
      // };
      const handleDayClick = (e) => {
        e.preventDefault();
        setIsDayOpen(!isDayOpen);
      };

      // const handleMonthClick = (e) => {
      //   e.preventDefault();
      //   setIsMonthOpen(!isMonthOpen);
      // };
  

    const content = <div style={{display: "flex", flexDirection: "column"}}>
    <div><span>Pick a date for daily report:  </span>
    <Button style={{marginLeft: "4%"}} color="blue" onClick={handleDayClick}>
      {format(startDate, "dd-MM-yyyy")}
    </Button>
    {isDayOpen && (
      <DatePicker selected={startDate} onChange={handleDayChange}
      popperPlacement="bottom"
      minDate={subMonths(new Date(), 2)}
      maxDate={subDays(new Date(), 1)}
      inline
      />
    )}
    </div><br/>
    {/* <div><span>Pick a month for monthly report:  </span>
    <Button color="blue" onClick={handleMonthClick}>
      {format(startDate, "MMMM-yyyy")}
    </Button>
    {isMonthOpen && (
      <DatePicker
      selected={startDate}
      onChange={handleMonthChange}
      dateFormat="MM/yyyy"
      minDate={subMonths(new Date(), 2)}
      maxDate={subMonths(new Date(), 1)}
      showMonthYearPicker
      inline
    />
    )}
    </div> */}
  </div>

       
    return (
        <Modal content={content}/>
    )
}

const mapStateToProps = (state) => {
    return {plantID: state.plantID ? state.plantID.data : null,
            email: state.auth.user ? state.auth.user.payload.email : null,}
}

export default connect(mapStateToProps, { sendDailyReport })(MailPage);
import React, { useEffect } from "react";
import { Grid, Image } from "semantic-ui-react";
import { Chart } from "react-google-charts";
import { connect } from "react-redux";
import Slider from 'react-slick';
import { toast } from 'react-hot-toast';
import MiscCard from "../components/MiscCard";
import { logout } from "../actions/auth";
import ChartComponent from "../components/ChartComponent";



const Dashboard = (props) => {

  useEffect(()=>{

    if (props.plant.data && !props.plant.data.data.isActive) {
      props.logout()
      toast.error("Your plant is in deactivated state.  Please contact admin")
    }

  }, [props.plant.data])

    if (props.plant.data) {
      var max = parseFloat(props.plant.data.data.DCMW).toFixed(2) * 1000
    }

    
    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true

    };

  const getWmsTags = () => {
    if (props.plant.data) {
      return props.plant.data.data.wms.filter((wm)=>{
        if (wm.tags) {
          return wm
        } else {
          return null
        }
      })
      .map((w)=>{
        return w.tags.map((tag)=>{
          return [tag.tagID, tag.tagValue]
        })
        .reduce((acc, obj)=>{
          return obj
        }, 0)
      })
      
    }
  }

const renderTrf = () => {
  return props.plant.data.data.transformer.map((trf, index)=>{
    return (
      <div className="ui fluid card" key={index}>
        <div className="content center aligned" style={{backgroundColor: '#e4e4e4', height: '40px'}}>
            <div className="header">Transformer {trf.trfID}</div>
        </div>
        {getTrfData(trf)}
      </div>
    )
  })
}

const getTrfData = (trf) => {
  if (trf.tags) {
    return trf.tags.map((tag, index)=>{
      return (
        <div className="content" key={index}>
          <span className="ui small teal label">{tag.tagID}</span>
          <span className="right floated">{parseFloat(tag.tagValue).toFixed(2)} {tag.unit}</span>
        </div>

      )
    })
  }
}

const renderVcbCurrent = () => {
  return props.plant.data.data.vcb.map((vc, index)=>{
    return (
      <div className="ui fluid card raised" key={index}>
          <div className="content center aligned" style={{backgroundColor: '#e4e4e4', height: '40px'}}>
            <div className="header" style={{display: 'flex', justifyContent: 'space-between'}}><div>CURRENT</div><div className="meta">VCBID: {vc.vcbID}</div></div>
          </div>
          <div className="content aligned">
            <span className="right floated time">{parseFloat(getVcbData(vc, 'CUR_R')).toFixed(2)} A</span>
            <span className="ui small red label">A</span>
          </div>
          <div className="content aligned">
            <span className="right floated time">{parseFloat(getVcbData(vc, 'CUR_Y')).toFixed(2)} A</span>
            <span className="ui small red label">B</span>
          </div>
          <div className="content aligned">
            <span className="right floated time">{parseFloat(getVcbData(vc, 'CUR_B')).toFixed(2)} A</span>
            <span className="ui small red label">C</span>
          </div>
        </div>      
    )
  })
}

const renderVcbVoltage = () => {
  return props.plant.data.data.vcb.map((vc, index)=>{
    return (
      <div className="ui fluid card raised" key={index}>
        <div className="content center aligned" style={{backgroundColor: '#e4e4e4', height: '40px'}}>
        <div className="header" style={{display: 'flex', justifyContent: 'space-between'}}><div>VOLTAGE</div><div className="meta">VCBID: {vc.vcbID}</div></div>
            </div>
            
            <div className="content aligned">
                <span className="right floated time">{parseFloat(getVcbData(vc, 'VOLT_RY')).toFixed(2)} KV</span>
                <span className="ui small red label">A</span>
            </div>
            <div className="content aligned">
                <span className="right floated time">{parseFloat(getVcbData(vc, 'VOLT_YB')).toFixed(2)} KV</span>
                <span className="ui small red label">B</span>
            </div>
            <div className="content aligned">
                <span className="right floated time">{parseFloat(getVcbData(vc, 'VOLT_BR')).toFixed(2)} KV</span>
                <span className="ui small red label">C</span>
            </div>

            
          </div>      
    )
  })
}

const getVcbData = (vc, tagKey) => {
  if (vc.tags) {
    return vc.tags.filter((tag)=>{
      return tag.tagID === tagKey
    }).reduce((acc, obj)=>{
      return obj.tagValue
    }, 0)
  }
}

  const getMscData = (tagKey) => {
    if (props.plant.data) {

    
    return props.plant.data.data.msc
      .filter((ms) => {
        if (ms.tags) {
          return ms.tags[0].tagID === tagKey;
        } else {
          return null
        }
      })
      .map((data) => {
        return data.tags[0].tagValue;
      });
    }
  }

  
// console.log(props.plant.data)


    if (!props.plant.data || props.plant.data === undefined) {
      return <div>Loading...</div>;
    }

    const renderWms = () => {
      
        return (
          <div className="ui fluid card">
            <div className="content center aligned" style={{backgroundColor: '#e4e4e4', height: '40px'}}>
                <div className="header">Weather</div>
            </div>
            {getWmsData()}
          </div>
        )
    }
    
    const getWmsData = () => {
      
        return props.plant.data.data.wms.map((wm)=>{
          if (wm.tags) {
            return (wm.tags.map((tag, index)=>{
              return (
                <div className="content" key={index}>
                <span className="ui small orange label">{tag.tagID}</span>
                <span className="right floated">{parseFloat(tag.tagValue).toFixed(2)} {tag.unit}</span>
              </div>
              )
            })
      
            )            
          } else {
            
            return null
          }
          
        })
      
    }

    const renderGuageChart = () => {
      const trendOptionData1 = {
        labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        datasets: [{
          label: 'Weekly Sales',
          data: [18, 12, 6, 9, 12, 3, 9],
          backgroundColor: [
            'rgba(255, 26, 104, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
            'rgba(0, 0, 0, 0.2)'
          ],
          borderColor: [
            'rgba(255, 26, 104, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
            'rgba(0, 0, 0, 1)'
          ],
          borderWidth: 1
        }]
      };

      const addOptions = {
        trendOptionData1,
        options: {
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }
      };

      const chartType = 'line'

      return (

        <ChartComponent optionData={trendOptionData1} addOptions={addOptions} chartType={chartType}/>
      )
    }

    if (props.isMobile) {
      return (
      <div>
        <MiscCard />
        <div className="ui card fluid raised">
            <div className="content center aligned" style={{backgroundColor: '#e4e4e4', height: '40px'}}>
              <div className="header">Active Power</div>
            </div>
            <div className="content">
              <Chart
                chartType="Gauge"
                width="100%"
                height='150px'
                data={[
                  ["Label", "Value"],
                  ["KW", parseInt(getMscData('ALL_VCB_ACT_POWER'))],
                ]}
                options={{ width: 400, height: 150, minorTicks: 5, max: max }}

              />
            </div>
          </div>
          <Slider {...settings}>
          {renderVcbVoltage()}
          </Slider>
          <Slider {...settings}>
          {renderVcbCurrent()}
          </Slider>
          <div className="ui fluid card raised">
          
            <div className="content center aligned" style={{backgroundColor: '#e4e4e4', height: '40px'}}>
              <div className="header" >Plant Status</div>
            </div>
              
              <div
              className="content center aligned" style={{paddingTop: 40}}>
              <Image src={parseInt(getMscData('PLANT_STATUS').toString()) ? require("../assets/images/plantOnline.gif") : require("../assets/images/plantOffline.gif")} size='small'/>
              
            </div>
            
            
          </div>
          {renderWms()}
          <div className="ui fluid card raised">
          <Slider {...settings}>
          {renderTrf()}
          </Slider>
          </div>

      </div>
      )
    }
    
  return (
    <div style={{overflow: 'auto', height: '75vh'}}>
    <Grid>
      <Grid.Row stretched centered>
        {/* <Grid.Column width={2}>check1</Grid.Column> */}
        <Grid.Column width={12}>
          <MiscCard />
        </Grid.Column>
        <Grid.Column width={4}>
          <div className="ui card fluid raised">
            <div className="content center aligned" style={{backgroundColor: '#e4e4e4', height: '20%'}}>
              <div className="header">Active Power</div>
            </div>
            <div className="content">
              <Chart
                chartType="Gauge"
                width="100%"
                height='150px'
                data={[
                  ["Label", "Value"],
                  ["KW", parseInt(getMscData('ALL_VCB_ACT_POWER'))],
                ]}
                options={{ width: 400, height: 150, minorTicks: 5, max: max }}
              />
              {/* {renderGuageChart()} */}
            </div>
          </div>
          
          <Slider {...settings}>
          {renderVcbVoltage()}
          </Slider>
          
          
        </Grid.Column>
      </Grid.Row>
      <Grid.Row stretched style={{paddingBottom: '2rem'}}>
        <Grid.Column width={4}>
        
        <div className="ui fluid card raised">
          
            <div className="content center aligned" style={{backgroundColor: '#e4e4e4', height: '20%'}}>
              <div className="header" >Plant Status</div>
            </div>
            {parseInt(getMscData('PLANT_STATUS').toString()) ? (
              
              <div
              className="content center aligned" style={{paddingTop: 40}}>
              <Image src={require('../assets/images/plantOnline.gif')} size='small'/>
              
            </div>
            
            )
            : (
              
              <div
              className="content center aligned" style={{paddingTop: 40}}>
              <Image src={require('../assets/images/plantOffline.gif')} size='small' />

            </div>
            
            )
            }
          </div>
          
        </Grid.Column>
        <Grid.Column width={4}>
        {/* <div className="ui fluid card raised">
        <div className="content center aligned" style={{backgroundColor: '#e4e4e4', height: '20%'}}>
              <div className="header">Weather</div>
            </div>
            {getWmsTags().map((item, index)=>{
              return (
              <div className="content aligned" key={index}>
                <span className="right floated time">{parseFloat(item[1]).toFixed(2)}</span>
                <span className="ui small orange label">{item[0]}</span>
            </div>
              );
            })}
          </div> */}
          {renderWms()}
        </Grid.Column>
        <Grid.Column width={4}>
        <div className="ui fluid card raised">
          <Slider {...settings}>
          {renderTrf()}
          </Slider>
          </div>
          </Grid.Column>
        <Grid.Column width={4}>
          <Slider {...settings}>
          {renderVcbCurrent()}
          </Slider>
        
        </Grid.Column>
      </Grid.Row>
    </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  // console.log(state)
    return { plant: state.plant, plantID: state.plantID ? state.plantID.data : null, isMobile: state.isMobile.isMobile }
}

export default connect(mapStateToProps, { logout })(Dashboard);

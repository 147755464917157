import React from 'react';
import { connect } from 'react-redux';
import { Menu, Table } from 'semantic-ui-react';

const AcdbTable = (props) => {

    const renderAcbData = (tagKey) => {
      return props.plant.data.data.transformer.map((trf)=>{
        return trf.acdb.map((ac, index)=>{
          return (
            <Table.Cell key={index}>{parseFloat(getAcdbData(ac, tagKey)).toFixed(2)}</Table.Cell>
          )
        })
      }) 
    }

    const getAcdbData = (ac, tagKey) =>{
      if (ac.tags) {
        return ac.tags.filter((tag)=>{
          return tag.tagID === tagKey
        }).reduce((acc, obj)=>{
          return obj.tagValue
        }, 0)
      }
    }

    const getAcdbID = () => {
      return props.plant.data.data.transformer.map((trf)=>{
        return trf.acdb.map((ac, index)=>{
          if (ac.tags) {
            return (
              <Table.HeaderCell key={index}>ACDBID: {ac.acdbID}</Table.HeaderCell>
            )
          } else {
            return null
          }
          
        })
      })
    }


     if (!props.plant.data) {
  return (
    <div>Loading...</div>
  )
}


    return (
      <div style={{overflow: 'auto', height: '75vh'}}>
            
            <Menu style={{alignItems: 'center', justifyContent: 'center'}}>
            <div style={{fontSize: 50}}>AC DISTRIBUTION BOARD</div>
        </Menu>
            
            
            <Table striped color='grey' inverted celled padded='very' style={{margin: 20}}>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell></Table.HeaderCell>
        {getAcdbID()}
      </Table.Row>
    </Table.Header>

    <Table.Body>
      <Table.Row>
        <Table.Cell>R PHASE METER CURRENT (A)</Table.Cell>
        {renderAcbData('CUR_R')}
      </Table.Row>
      <Table.Row>
        <Table.Cell>Y PHASE METER CURRENT (A)</Table.Cell>
        {renderAcbData('CUR_Y')}
      </Table.Row>
      <Table.Row>
        <Table.Cell>B PHASE METER CURRENT (A)</Table.Cell>
        {renderAcbData('CUR_B')}
      </Table.Row>
      <Table.Row>
        <Table.Cell>RY LINE VOLTAGE (V)</Table.Cell>
        {renderAcbData('VOLT_RY')}
      </Table.Row>
      <Table.Row>
        <Table.Cell>YB LINE VOLTAGE (V)</Table.Cell>
        {renderAcbData('VOLT_YB')}
      </Table.Row>
      <Table.Row>
        <Table.Cell>BR LINE VOLTAGE (V)</Table.Cell>
        {renderAcbData('VOLT_BR')}
      </Table.Row>
      <Table.Row>
        <Table.Cell>ACTIVE POWER (KW)</Table.Cell>
        {renderAcbData('ACT_PWR')}
      </Table.Row>
      <Table.Row>
        <Table.Cell>TOTAL KWH EXPORT (kWh)</Table.Cell>
        {renderAcbData('ACT_PWR_EXPORT')}
      </Table.Row>
      <Table.Row>
        <Table.Cell>AVERAGE PF (PF)</Table.Cell>
        {renderAcbData('AVG_PF')}
      </Table.Row>
      <Table.Row>
        <Table.Cell>FREQUENCY (Hz)</Table.Cell>
        {renderAcbData('FREQ')}
      </Table.Row>
    </Table.Body>
  </Table>
  </div>

    );
}

const mapStateToProps = (state) => {
  return {plant: state.plant}
}

export default connect(mapStateToProps)(AcdbTable);
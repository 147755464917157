import { TOGGLE_SIDEBAR } from "../actions/types";

const INITIAL_STATE = {
    isVisible: false
}

const sidebarReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TOGGLE_SIDEBAR:
            return {...state, isVisible: action.payload}
        default:
            return state;
    }
}

export default sidebarReducer;
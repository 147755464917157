export default function authHeader() {
  const token = JSON.parse(localStorage.getItem("token"));
  const user = JSON.parse(localStorage.getItem("user"));
  const resetToken = JSON.parse(localStorage.getItem("resetToken"))

  if (user && token) {
    // For Spring Boot back-end
    return { Authorization: "Bearer " + token };

    // for Node.js Express back-end
    // return { "x-access-token": user.accessToken };
  } else if (resetToken) {
    return { Authorization: "Bearer " + resetToken }
  } else {
    return {};
  }
}

import React, { useRef } from 'react';
import Chart from 'chart.js/auto';
import {
  // Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import {Chart as ChartJS, Line, Doughnut, Bar } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom'
import { Button } from 'semantic-ui-react';

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  zoomPlugin,
  Title,
  Tooltip,
  Legend
  );

  const zoomOptions = {
    limits: {
      x: {min: 0, minRange: 50},
      y: {min: 0, minRange: 50}
    },
    pan: {
      enabled: true,
      mode: 'xy',
    },
    zoom: {
      wheel: {
        enabled: true,
      },
      pinch: {
        enabled: true
      },
      mode: 'xy',
      onZoomComplete({chart}) {
        // This update is needed to display up to date zoom level in the title.
        // Without this, previous zoom level is displayed.
        // The reason is: title uses the same beforeUpdate hook, and is evaluated before zoom.
        chart.update('none');
      }
    }
  };

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    zoom: zoomOptions,
    title: {
      display: false,
      position: 'bottom',
      text: "Line Chart"
    },
  },
  
};



// const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

// export const data = {
//   labels,
//   datasets: [
//     {
//       label: 'Dataset 1',
//       data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
//       borderColor: 'rgb(255, 99, 132)',
//       backgroundColor: 'rgba(255, 99, 132, 0.5)',
//     },
//     {
//       label: 'Dataset 2',
//       data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
//       borderColor: 'rgb(53, 162, 235)',
//       backgroundColor: 'rgba(53, 162, 235, 0.5)',
//     },
//   ],
// };

const ChartComponent = (props) => {

  const chartRef = useRef()

  const handleResetZoom = () => {
    if (chartRef && chartRef.current) {
      chartRef.current.resetZoom();
    }
  };


    const { optionData, addOptions } = props

    const additionalOptions = Object.assign(options, addOptions)

  return (
    <div>
    <div style={{position: "relative", height: "55vh"}}>
      {props.chartType === 'line' ? <Line ref={chartRef} options={additionalOptions} data={optionData} width={"100%"} />
      :
      <ChartJS ref={chartRef} options={additionalOptions} data={optionData} width={"100%"} />
      }
    </div>
    <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
    <Button color='blue' onClick={handleResetZoom}>Reset Zoom</Button>
    </div>
    </div>
  
  );
}

export default ChartComponent;
import React from 'react';
import { connect } from 'react-redux';
import { Menu, Table } from 'semantic-ui-react';

const VcbTable = (props) => {

      const rendervcbData = (tagKey) => {
        
            return props.plant.data.data.vcb.map((vc, index)=>{
                if (tagKey === 'STATUS') {
                    if (getvcbData(vc, tagKey) === '1') {
                        return (
                            <Table.Cell key={index}>ON</Table.Cell>
                        )        
                    } else {
                        return (
                            <Table.Cell key={index}>OFF</Table.Cell>
                        )                            
                    }
                }
                return (
                    <Table.Cell key={index}>{parseFloat(getvcbData(vc, tagKey)).toFixed(2)}</Table.Cell>
                )        
            }) 
        
      }
  
      const getvcbData = (vc, tagKey) =>{

            if (vc.tags) {
                return vc.tags.filter((tag)=>{
                  return tag.tagID === tagKey
                }).reduce((acc, obj)=>{
                  return obj.tagValue
                }, 0)
              }
        
      }
  
      const getVcbID = () => {

            return props.plant.data.data.vcb.map((vc, index)=>{
                return (
                    <Table.HeaderCell key={index}>VCBID: {vc.vcbID}</Table.HeaderCell>
                )           
              
            })
            
      }

      if (!props.plant.data) {
        return (
            <div>Loading...</div>
        )
      }

    return (
      <div style={{overflow: 'auto', height: '75vh'}}>
        
      <Menu style={{alignItems: 'center', justifyContent: 'center'}}>
          <div style={{fontSize: 50}}>VACUUM CIRCUIT BREAKER</div>
      </Menu>
            <Table striped color='grey' inverted celled padded='very' style={{margin: 20}}>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell></Table.HeaderCell>
        {getVcbID()}
      </Table.Row>
    </Table.Header>

    <Table.Body>
      <Table.Row>
        <Table.Cell>R PHASE METER CURRENT (A)</Table.Cell>
        {rendervcbData('CUR_R')}
      </Table.Row>
      <Table.Row>
        <Table.Cell>Y PHASE METER CURRENT (A)</Table.Cell>
        {rendervcbData('CUR_Y')}
      </Table.Row>
      <Table.Row>
        <Table.Cell>B PHASE METER CURRENT (A)</Table.Cell>
        {rendervcbData('CUR_B')}
      </Table.Row>
      <Table.Row>
        <Table.Cell>R-N LINE VOLTAGE (V)</Table.Cell>
        {rendervcbData('VOLT_RY')}
      </Table.Row>
      <Table.Row>
        <Table.Cell>Y-N LINE VOLTAGE (V)</Table.Cell>
        {rendervcbData('VOLT_YB')}
      </Table.Row>
      <Table.Row>
        <Table.Cell>B-N LINE VOLTAGE (V)</Table.Cell>
        {rendervcbData('VOLT_BR')}
      </Table.Row>
      <Table.Row>
        <Table.Cell>ACTIVE POWER (KW)</Table.Cell>
        {rendervcbData('ACT_PWR_EXPORT')}
      </Table.Row>
      <Table.Row>
        <Table.Cell>TODAY POWER EXPORT (kWh)</Table.Cell>
        {rendervcbData('TOD_EXPORT')}
      </Table.Row>
      <Table.Row>
        <Table.Cell>TOTAL POWER EXPORT (kWh)</Table.Cell>
        {rendervcbData('TTL_EXPORT')}
      </Table.Row>
      <Table.Row>
        <Table.Cell>AVERAGE POWER FACTOR (PF)</Table.Cell>
        {rendervcbData('PF')}
      </Table.Row>
      <Table.Row>
        <Table.Cell>FREQUENCY (Hz)</Table.Cell>
        {rendervcbData('FREQ')}
      </Table.Row>
      <Table.Row>
        <Table.Cell>STATUS</Table.Cell>
        {rendervcbData('STATUS')}
      </Table.Row>
    </Table.Body>
  </Table>
</div>
    );
}

const mapStateToProps = (state) => {
    return {plant: state.plant}
}

export default connect(mapStateToProps)(VcbTable);
import React, { useEffect, useState } from 'react';
import { Button, Icon, Table } from 'semantic-ui-react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { subDays, subMonths } from 'date-fns';
import { connect } from 'react-redux';
import { getAllAlarmsInRange } from '../actions/plantAction';


const AllAlarms = (props) => {

    const {getAllAlarmsInRange, plantID, alarmsInRange} = props

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [isOpen, setIsOpen] = useState(false);

    useEffect(()=>{

      if (plantID) {
        getAllAlarmsInRange({plantID, fromDate: startDate, toDate: endDate})
      }

    }, [plantID, getAllAlarmsInRange])

    const onChange = async (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        if (end) {
            getAllAlarmsInRange({plantID, fromDate: start, toDate: end})
            setIsOpen(!isOpen)
        }
      };
    
    const handleClick = (e) => {
        e.preventDefault();
        setIsOpen(!isOpen);
      };    

    return (
        <div>
            <div >
                <div style={{justifyContent: "center", flexDirection: "column", textAlign: "center"}}>
                <h1>Alarms</h1>
                <Button color="blue" className="example-custom-input" onClick={handleClick}>
                {`${moment(startDate).format("DD-MM-YYYY")} to ${moment(endDate).format("DD-MM-YYYY")}`}
                </Button>
                {isOpen && (
                    <DatePicker 
                    popperPlacement="bottom"
                    minDate={subMonths(new Date(), 2)}
                    maxDate={new Date()}
                    selected={startDate}
                    onChange={onChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    inline
                    />
                    )}
                </div>
            </div>
            <div style={{paddingTop: 30}}>
            {alarmsInRange ? <Table celled unstackable style={{overflow: 'auto'}}>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>PLANT ID</Table.HeaderCell>
        <Table.HeaderCell>ALARM DESC</Table.HeaderCell>
        <Table.HeaderCell>START TIME</Table.HeaderCell>
        <Table.HeaderCell>END TIME</Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>
    {alarmsInRange.map((alarm, index)=>{
        return (
            <Table.Row key={index} error={!!alarm.almTagValue}>
        <Table.Cell>{alarm.plantID}</Table.Cell>
        <Table.Cell>{alarm.almDesc}</Table.Cell>
        <Table.Cell>{moment(alarm.startTime).format("DD-MM-YYYY hh:mm:ss")}</Table.Cell>
        <Table.Cell>{alarm.endTime.length ? moment(alarm.endTime).format("DD-MM-YYYY hh:mm:ss") : "Active"}</Table.Cell>
      </Table.Row>
        )
    })

    }
    </Table.Body>
  </Table> 
  :
  <div style={{textAlign: "center"}}>
    <h1>No Data Found</h1>
    </div>}
            </div>
        </div>

    )
}

const mapStateToProps = (state) => {
    return {plantID: state.plantID ? state.plantID.data : null,
            alarmsInRange: state.alarmRange ? state.alarmRange.data : null}
}

export default connect(mapStateToProps, { getAllAlarmsInRange })(AllAlarms);
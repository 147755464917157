import React from 'react';

import { useJsApiLoader, GoogleMap, Marker, MarkerF, InfoBox } from '@react-google-maps/api';
import { Link } from 'react-router-dom';
import { Icon, Label } from 'semantic-ui-react';


const containerStyle = {
    width: '100%',
    height: '100%'
  };

const center = {
    lat: 11.298268591626814,
    lng: 78.5831565412183
  };
  

const MapComponent = (props) => {

    const {isLoaded} = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    })

    if (!isLoaded) {
        return (
            <div>Loading...</div>
        )
    }

const varCenter = props.plant.map((sPlant)=>[parseFloat(sPlant.lat), parseFloat(sPlant.long)])

let sumLat = 0;
let sumLng = 0;
let avgLat = 0;
let avgLng = 0;

for (var i=0; i<varCenter.length; i++) {
    sumLat = sumLat + parseFloat(varCenter[i][0])
    sumLng = sumLng + parseFloat(varCenter[i][1])
}

avgLat = sumLat/varCenter.length
avgLng = sumLng/varCenter.length

let avgCenter = {
    lat: avgLat,
    lng: avgLng
}

    const renderMarker = props.plant.map((sPlant, index)=>{
                if (sPlant.isActive) {
                    return (
                        <Marker key={index} position={{lat: parseFloat(sPlant.lat), lng: parseFloat(sPlant.long)}} label={sPlant.plantName.split(" ")[0]}/>                
                    )
                }
                    
                }
    )

    const renderMarkerF = props.plant.map((location)=>{
        return <MarkerF key={location.plantID} position={{lat: parseFloat(location.lat), lng: parseFloat(location.long)}} />
    })

    const infoOptions = { closeBoxURL: '', enableEventPropogration: true, boxStyle: {maxWidth: '150px'} }

    const infoOnLoad = infoBox => {
        // console.log('infoBox: ', infoBox)
      };

    const renderInfoBox = props.plant.map((box) => {
        return (
            <InfoBox
                key={box.plantID}
                onLoad={infoOnLoad}
                options={infoOptions}
                position={{lat: parseFloat(box.lat), lng: parseFloat(box.long)}}
                
                >
                <Label basic color='red' pointing="below" size='tiny'>
                    <Icon name='tree' />
                    <Link to="dashboard" onClick={()=>props.handleClick(box.plantID)}>{box.plantName.split(" ")[0]}</Link>
                </Label>
            </InfoBox>
        )
    })

    
    return (
        <div style={{height: '75vh'}}>
            <GoogleMap
            mapContainerStyle={containerStyle}
            // center={center}
            // center={avgCenter}
            // zoom={15}
            options={{
                zoomControl: true,
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl: true
            }}
            onLoad={(map)=>{
                const bounds = new window.google.maps.LatLngBounds();
                props.plant.forEach((location)=>{
                    bounds.extend({lat: parseFloat(location.lat), lng: parseFloat(location.long)})
                })
                map.fitBounds(bounds)               
            }}
            >
                {/* {renderMarker} */}
                {/* {renderMarkerF} */}
                {renderInfoBox}
            </GoogleMap>
        </div>
    )
}

export default MapComponent;
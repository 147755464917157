import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

const Modal = (props) => {

    // console.log(props);

    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    }


    return ReactDOM.createPortal(
        <div onClick={goBack} className='ui dimmer modals visible active'>
            <div onClick={(e)=>e.stopPropagation()} className='ui standard modal visible active'>
                <div className='content'>
                    {props.content}
                </div>
            </div>
        </div>,
        document.querySelector('#modal')
    );
};

export default Modal;
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import "./ResetPassword.css";

import 'react-toastify/dist/ReactToastify.css'
import backendApi from "../apis/backendApi";
import authHeader from "../services/auth-header";

const ResetPassword = () => {

    const navigate = useNavigate();

    let params = useParams();

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
  
    const resetPasswordHandler = async (e) => {
        e.preventDefault();
  

      if (password !== confirmPassword) {
        setPassword("");
        setConfirmPassword("");
        setTimeout(() => {
          setError("");
        }, 5000);
        return alert("Passwords don't match");
      }
  
      try {
        localStorage.setItem("resetToken", JSON.stringify(params.resetToken))
        
        const resetResponse = await backendApi.post(
          `/resetpassword/${params.resetToken}`,
          {
            password,
          },
          {headers: authHeader()}
        );
        
        if (resetResponse.data.data.code === 203) {
            alert("Token is invalid.  Please use the reset link within 5 minutes of generation")
        }
        setSuccess(resetResponse.data.data.message);
        setPassword("")
        setConfirmPassword("")
        navigate("/login")
      } catch (err) {
        console.log(err)
        alert(err);
        setTimeout(() => {
          setError("");
        }, 5000);
      }
    };
  
    return (
      <div>
        <div className="bold-line"></div>
        <div className="password-container">
          <form onSubmit={(e)=>resetPasswordHandler(e)} className="window"> 
            <div className="overlay"></div>
            <div className="reset-content">
              {/* {error && (<span className="error-message">{error}</span>)} */}
              {/* {success && (<span className="success-message">{success}</span>)} */}
              <div className="welcome">Reset Password</div>
              <div className="subtitle">
              Please type new password and re-type password to confirm it.
              </div>
              <div className="input-fields">
                <input
                  type="password"
                  placeholder="New Password"
                  className="input-line full-width"
                  required
                  value= {password}
                  onChange={(e)=> setPassword(e.target.value)}
                ></input>
                    <input
                  type="password"
                  placeholder="Confirm New Password"
                  className="input-line full-width"
                  required
                  value= {confirmPassword}
                  onChange={(e)=> setConfirmPassword(e.target.value)}
                ></input>
                  
              </div>
              <div>
                <button  className="ghost-round full-width" >Reset Password</button>
                <Link to="/login"><button className="ghost-round signin">SIGN IN</button></Link>
                </div>
            </div>
          </form>
        </div>
      </div>
    );
  };
  
  export default ResetPassword;
  
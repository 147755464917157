import React from 'react';
import { connect } from 'react-redux';
import { Tree, TreeNode } from 'react-organizational-chart';
import { Segment } from 'semantic-ui-react';

const SingleLineDiagram = (props) => {

    if (!props.plantID) {
        
        return
    }

    if (!props.plant.data) {
        return (
          <div>Loading...</div>
        )
      }

      
    //   const getVcbTag = (vcbID, tagKey) => {
    //       if (props.plant.data) {
    //           return props.plant.data.data.vcb.filter((vc)=>{
    //               return vc.vcbID === vcbID
    //           }).map((vc)=>{
    //               return vc.tags.filter((tag)=>{
    //                   return tag.tagID === tagKey
    //               }).reduce((acc, tag)=>{return tag.tagValue}, 0)
    //           }).reduce((acc, tag)=>{return tag}, 0)
    //       }
    //   }

      const renderVcb = (trfID) => {
        if (props.plant.data) {
            return props.plant.data.data.vcb.filter((vc)=>{
                return vc.vcbID === trfID
            }).map((vc)=>{

                return vc.tags.map((tag, index)=>{
                    if (tag.tagID === 'STATUS' && tag.tagValue === '1') {
                        return (
                            <div key={index} style={{textAlign: 'left'}}>{tag.tagID}: <span style={{color: 'orangered'}} >ON</span><br/></div>
                        )                        
                    } else if (tag.tagID === 'STATUS' && tag.tagValue === '0') {
                        return (
                            <div key={index} style={{textAlign: 'left'}}>{tag.tagID}: <span style={{color: 'orangered'}} >OFF</span><br/></div>
                        )
                    } else {
                        return (
                            <div key={index} style={{textAlign: 'left'}}>{tag.tagID}: <span style={{color: 'orangered'}}>{parseFloat(tag.tagValue).toFixed(2)}</span> {tag.unit ? tag.unit : ""}<br/></div>
                        )
                    }

                })})
        }
      }
      
      const renderAcdb = (trf) => {
          if (props.plant.data) {
              return trf.acdb.map((ac, index)=>{
                  return  (
                      <TreeNode key={index} label={
                          <Segment raised style={{ display: "inline-block", marginBottom: 0 }}>
                            <>
                            ACDB: <span style={{color: 'orangered'}}>{ac.acdbID}</span><br />
                            {renderAcdbTags(ac)}
                            </>
                   </Segment>} >
                      {renderInverter(ac)}
                   </TreeNode>
                  )
                  
              })
          }
          
      }

      const renderAcdbTags = (acArray) => {
        if (acArray.tags) {
            return acArray.tags.map((tag, index)=>{
                return (
                    <div key={index} style={{textAlign: 'left'}}>{tag.tagID}: <span style={{color: 'orangered'}}>{parseFloat(tag.tagValue).toFixed(2)}</span> {tag.unit ? tag.unit : ""}<br/></div>
                )
            })
        }
        
      }

      const renderInverter = (arr) => {
        
          if (props.plant.data) {

            if (arr.inverter) {
                return arr.inverter.map((inv, index)=>{
                    return (
                        <TreeNode key={index} label={<ul style={{marginLeft: -40}}><TreeNode label={<Segment raised style={{ display: "inline-block", marginBottom: 0}}>
                  <>
                  <div style={renderInvTags(inv)[0][0] ? {backgroundColor: "teal"} : {backgroundColor: "red"}}>INV: <span style={{color: 'white'}}>{inv.invID}</span></div><br />
                  {/* DC<br />
                  PW: {parseFloat(inv.tags.filter((tag)=>{return tag.tagID === 'DC_PWR'}).reduce((acc, tag)=>{return tag.tagValue}, 0)).toFixed(2)} KW<br />
                  L1: {parseFloat(inv.tags.filter((tag)=>{return tag.tagID === 'C1'}).reduce((acc, tag)=>{return tag.tagValue}, 0)).toFixed(2)} A<br />
                  L2: {parseFloat(inv.tags.filter((tag)=>{return tag.tagID === 'C2'}).reduce((acc, tag)=>{return tag.tagValue}, 0)).toFixed(2)} A<br />
                  L3: {parseFloat(inv.tags.filter((tag)=>{return tag.tagID === 'C3'}).reduce((acc, tag)=>{return tag.tagValue}, 0)).toFixed(2)} A<br />
                  PW: {parseFloat(inv.tags.filter((tag)=>{return tag.tagID === 'ACT_PWR'}).reduce((acc, tag)=>{return tag.tagValue}, 0)).toFixed(2)} KW<br /> */}
                  {renderInvTags(inv)}
                  </>
                  </Segment>} >
                    {inv.scb ? renderScb(inv) : <TreeNode label={
                    <Segment padded={"very"} raised style={{ display: "inline-block", marginBottom: 0 }}>
                    <>
                      String {inv.strNumbers} Nos<br />
                      MODULES<br />
                      {inv.moduleNumbers} Nos of {inv.moduleType} Wp ({parseFloat(parseInt(inv.moduleNumbers)*parseInt(inv.moduleType)/1000).toFixed(3)} KWp) <br />
                      TOTAL {parseFloat(parseInt(inv.moduleNumbers)*parseInt(inv.moduleType)*parseInt(inv.strNumbers)/1000).toFixed(3)} KWp
                      </>
                    </Segment>} >
                    </TreeNode>}
                    </TreeNode>
                    </ul>} />                    
                    )
                })
            } else {
                return null
            }
            
              
          }
      }

      const renderInvTags = (invArray) => {
        if (invArray.tags) {
            var changeColor;
            return invArray.tags.map((tag, index)=>{
                if (tag.tagID === 'ACT_PWR' && tag.tagValue >0) {
                    changeColor = false
                } else {
                    changeColor = true
                }
                return [changeColor, (<div key={index} style={{textAlign: 'left'}}>{tag.tagID}: <span style={{color: 'orangered'}}>{parseFloat(tag.tagValue).toFixed(2)}</span> {tag.unit ? tag.unit : ""}<br/></div>)]
            })
        }
      }


      const renderTrfTags = (trfArray) => {
        if (trfArray.tags) {
            return trfArray.tags.map((tag, index) => {
                return (<div key={index} style={{textAlign: 'left'}}>{tag.tagID}: <span style={{color: 'orangered'}}>{parseFloat(tag.tagValue).toFixed(2)} </span> {tag.unit ? tag.unit : ""}<br/></div>)
            })            
        }
      }

      const renderScb = (invArray) => {
        
        if (props.plant.data) {

          if (invArray.scb) {
              return invArray.scb.map((sc, index)=>{
                  return (
                      <TreeNode key={index} label={<ul style={{marginLeft: -40}}><TreeNode label={<Segment raised style={{ display: "inline-block", marginBottom: 0 }}>
                <>
                SCBID: <span style={{color: 'orangered'}}>{sc.scbID}</span><br />
                {/* DC<br />
                PW: {parseFloat(inv.tags.filter((tag)=>{return tag.tagID === 'DC_PWR'}).reduce((acc, tag)=>{return tag.tagValue}, 0)).toFixed(2)} KW<br />
                L1: {parseFloat(inv.tags.filter((tag)=>{return tag.tagID === 'C1'}).reduce((acc, tag)=>{return tag.tagValue}, 0)).toFixed(2)} A<br />
                L2: {parseFloat(inv.tags.filter((tag)=>{return tag.tagID === 'C2'}).reduce((acc, tag)=>{return tag.tagValue}, 0)).toFixed(2)} A<br />
                L3: {parseFloat(inv.tags.filter((tag)=>{return tag.tagID === 'C3'}).reduce((acc, tag)=>{return tag.tagValue}, 0)).toFixed(2)} A<br />
                PW: {parseFloat(inv.tags.filter((tag)=>{return tag.tagID === 'ACT_PWR'}).reduce((acc, tag)=>{return tag.tagValue}, 0)).toFixed(2)} KW<br /> */}
                {renderScbTags(sc)}
                </>
                </Segment>}
                >
                  <TreeNode label={
                  <Segment raised style={{ display: "inline-block", marginBottom: 0 }}>
                    <>
                    STRING<br/>
                    {sc.strNumbers} Nos<br />
                    MODULES<br />
                    {sc.moduleNumbers} Nos of {sc.moduleType} Wp ({parseFloat(parseInt(sc.moduleNumbers)*parseInt(sc.moduleType)/1000).toFixed(3)} KWp) <br />
                    TOTAL<br/>
                    {parseFloat(parseInt(sc.moduleNumbers)*parseInt(sc.moduleType)*parseInt(sc.strNumbers)/1000).toFixed(3)} KWp
                    </>
                  </Segment>} 
                  />
                  </TreeNode>
                  </ul>} />                    
                  )
              })
          } else {
              return null
          }
          
            
        }
    }

    const renderScbTags = (scArray) => {
        if (props.plant.data) {
            if (scArray.tags) {
                return scArray.tags.map((tag, index)=>{
                    return (<div key={index} style={{textAlign: 'left'}}>{tag.tagID}: <span style={{color: 'orangered'}}>{parseFloat(tag.tagValue).toFixed(2)}</span> {tag.unit ? tag.unit : ""}<br/></div>)                    
                })
            }
        }
    }
     
      
      return (
        <div style={{overflow: 'auto', height: '75vh'}}>
          <Tree
        lineWidth={"2px"}
        lineColor={"blue"}
        lineBorderRadius={"10px"}
        lineHeight={"40px"}
        label={<Segment raised style={{ display: "inline-block", marginBottom: 0, borderRadius: '50px' }}>Grid</Segment>}
        >
          {props.plant.data.data.transformer.map((trf, index)=>{
              return <TreeNode key={index} label={
                  <ul style={{marginLeft: -40}}>
                  <TreeNode label={
                        <Segment raised style={{display: "inline-block", marginBottom: 0}}
                        >
                        <>
                          VCB: <span style={{color: 'orangered'}}>{trf.trfID}</span><br />
                          {/* L1:  {parseFloat(getVcbTag(trf.trfID, 'CUR_R')).toFixed(2)} A<br />
                          L2:  {parseFloat(getVcbTag(trf.trfID, 'CUR_Y')).toFixed(2)} A<br />
                          L3:  {parseFloat(getVcbTag(trf.trfID, 'CUR_B')).toFixed(2)} A<br />
                          {parseInt(getVcbTag(trf.trfID, 'ACT_PWR_EXPORT'))} KW */}
                          {renderVcb(trf.trfID)}
                          </>
                          </Segment>
                          }
                      >
                        
                          <TreeNode label={
                          <Segment compact raised style={{ display: "inline-block", marginBottom: 0 }}
                          >
                            <>
                            TRF: <span style={{color: 'orangered'}}>{trf.trfID}</span><br />
                            {/* Winding Temperature: {parseFloat(trf.tags.filter((tag)=>{return tag.tagID === 'WINDING_TEMP'}).reduce((acc, tag)=>{return tag.tagValue}, 0)).toFixed(2)} C<br />
                            Oil Temperature:  {parseFloat(trf.tags.filter((tag)=>{return tag.tagID === 'OIL_TEMP'}).reduce((acc, tag)=>{return tag.tagValue}, 0)).toFixed(2)} C */}
                            {renderTrfTags(trf)}
                            </>
                            </Segment>}
                            >
      
                              
                            </TreeNode>
                              
                              </TreeNode>
                            
                            </ul>
          }>
      
              {trf.acdb ? renderAcdb(trf) : renderInverter(trf)}            
      
          </TreeNode>
          })}        
        </Tree>
        </div>
      );
      }
      
      const mapStateToProps = (state) => {
      return {plant: state.plant, plantID: state.plantID ? state.plantID.data : null}
      }

export default connect(mapStateToProps)(SingleLineDiagram);
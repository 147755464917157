import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { subDays, subMonths } from 'date-fns';
import { getDataForTrends } from '../actions/plantAction';
import { Link, useParams } from 'react-router-dom';
import Modal from '../components/Modal';
import { Button } from 'semantic-ui-react';
import ChartComponent from '../components/ChartComponent';

const ChartPage = (props) => {

    const { getDataForTrends, plantID, trendData, plant } = props

    const [deviceID, setDeviceID] = useState(0)
    const { id } = useParams();

    const [startDate, setStartDate] = useState(new Date());
    const [isOpen, setIsOpen] = useState(false);

    useEffect(()=>{

      if (!plantID) {
        return
      }

      var fromDate;
      var toDate;

        if (!Array.isArray(plantID) && id !== '4') {
          fromDate=moment(startDate).format();
          toDate=moment(startDate).format();
            getDataForTrends({ plantID: plantID, range: "minute", fromDate, toDate });
          } else if (!Array.isArray(plantID) && id === '4') {
            toDate = moment(startDate).format();
            fromDate = moment(startDate).subtract(1, "months").format();
            getDataForTrends({ plantID: plantID, range: "daily", fromDate, toDate  });
          }

          setDeviceID(0)

    }, [getDataForTrends, plantID, id, startDate])

    
    const handleChange = (e) => {
      setIsOpen(!isOpen);
      setStartDate(e);

    };
    
    const handleClick = (e) => {
      e.preventDefault();
      setIsOpen(!isOpen);
    };

    const renderHeader = () => {
      return (
        <div>
          <div style={{textAlign: 'center'}}>{headingContent}</div>
          <div style={{textAlign: 'center'}}>
          <Button color='blue' onClick={handleClick}>
          {moment(startDate).format("DD-MM-YYYY")}
            </Button>
            {isOpen && (
            <div style={{position: "absolute", left: "45%", zIndex: 999}}>
            <DatePicker selected={startDate} onChange={handleChange}
            popperPlacement="bottom"
            minDate={subMonths(new Date(), 2)}
            maxDate={new Date()}
            inline
            />
            </div>
            )}
        </div>
        </div>
      )
    }

    if (!trendData.data) {
      return (
        <div>Loading...</div>
      )
    }

    if (trendData.data.length === 0) {
      return <div>
      {renderHeader()}
      <h1 style={{display: 'flex', justifyContent: "center"}}>No Data</h1>
      </div>
    }

    var trendOptionData1;
    var headingContent;
    var renderArray;
    var addOptions;
    var chartType;

    if (id === "1") {

      chartType = "mixed"

      renderArray = trendData.data ?
        plant.vcb.map((vc)=>{
          if (vc.tags) {
            return vc.vcbID
          } else {
            return null
          }
        })
         : null

      if (deviceID === 0) {

        if (renderArray.length == 1) {
          setDeviceID(renderArray[0])
          return
        }

        const content = renderArray.map((vcb, index)=>{
            return (
                <ul key={index}>
                    <li>
                    <Link to="" onClick={()=>setDeviceID(vcb)}>VCB {vcb}</Link>
                    </li>
                </ul>
                
            )
        })
        
return (
    <div>
        <Modal content={content}/>
    </div>
    );

      }

      // headingContent = <h1>DAILY ENERGY GENERATION AND PLANT PARAMETERS</h1>;
      headingContent = <div style={{marginBottom: 10}} >
        <h1>DAILY POWER GENERATION FOR VCB {deviceID ? deviceID : ""}</h1>
        {(trendData.data.length !== 0 && renderArray.length !== 1) && <Link to="" onClick={()=>setDeviceID(0)}><span align="right">Click here for the VCB list</span></Link>};
        </div>

      if (trendData.data.length === 0) {
        return <div>
        {renderHeader()}
        <h1 style={{display: 'flex', justifyContent: "center"}}>No Data</h1>
        </div>
      }
           
      const getVCBData = (array) => {
        if (trendData.data && Array.isArray(trendData.data)) {
          return array.map((data)=>{
            return [data.mqttTime, data.vcb.map((vc)=>{
              let filterArray = ["VOLT_RY", "VOLT_YB", "VOLT_BR", "CUR_R", "CUR_Y", "CUR_B", "TTL_ACT_PWR"]
              return vc.tags.filter((tag)=>{
                return filterArray.includes(tag.tagID)
              })
            }).flat()]
          })
        } else {
          return null
        }

      }

      const getMscData = (array) => {

        if (trendData.data && Array.isArray(trendData.data)) {
          return array.map((data)=>{
            return [ data.mqttTime, data.msc.map((ms)=>{
              let filterArray = ['VC_TKWH', 'IR_TOD', 'PR_TOD', 'PAVL_TOD', 'GRD_AVLT' ]
              return ms.tags.filter((tag)=>{
                return filterArray.includes(tag.tagID)
              })
            })]
          })
        } else {
          return  null
        }
  
      }

      // let trendData1 = getMscData(trendData.data) ? getMscData(trendData.data).map((data)=>{
      //     return [data[0], data[1].filter((inData)=>{
      //       return inData.length !==0
      //     })]
        
      // })
      // :
      // null

      let trendData1 = getVCBData(trendData.data) ? getVCBData(trendData.data).map((data)=>{
        return [data[0], data[1].filter((inData)=>{
          return inData.length !==0
        })]
    })
    :
    null

      // const flatten = (ary) =>{
      //   return ary.map((ar)=>{
          
      //     return [ar[0].split(" ")[1], ar[1].reduce((a, b) => a.concat(b[0].tagValue), []).map(Number) ].flat()
      //   })
      // }

      const flatten = (ary) => {
        return ary.map((ar)=>{
          return [ar[0].split(" ")[1], ar[1].reduce((a, b)=> a.concat(b.tagValue), []).map(Number)].flat()
        })
      }


      const trendGroupData = trendData1 ? flatten(trendData1).sort(function(a, b){return a[0].localeCompare(b[0])}) : null

      

      if (!trendGroupData || trendGroupData.length === 0) {
        return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        {renderHeader()}
        <h1 style={{display: 'flex', justifyContent: "center"}}>No Data</h1>
        </div>
      }

      const labels = trendGroupData.map((a)=>{
        return a[0].slice(0, -3)
      });

      // trendOptionData1 = {
      //   labels,
      //   datasets: [
      //     {
      //       label: 'VCB Active Power',
      //       data: trendGroupData.map((a)=>{
      //         return a[1]
      //       }),
      //       borderColor: 'rgb(255, 99, 132)',
      //       backgroundColor: 'rgba(255, 99, 132, 0.5)',
      //     },
      //     {
      //       label: 'Irradiance',
      //       data: trendGroupData.map((a)=>{
      //         return a[2]
      //       }),
      //       borderColor: 'rgb(53, 162, 235)',
      //       backgroundColor: 'rgba(53, 162, 235, 0.5)',
      //     },
      //     {
      //       label: 'Performance Ratio',
      //       data: trendGroupData.map((a)=>{
      //         return a[3]
      //       }),
      //       borderColor: 'rgb(130,119,7)',
      //       backgroundColor: 'rgba(130,119,7, 0.5)',
      //     },
      //     {
      //       label: 'Plant Availability',
      //       data: trendGroupData.map((a)=>{
      //         return a[4]
      //       }),
      //       borderColor: 'rgb(111,14,120)',
      //       backgroundColor: 'rgba(111,14,120, 0.5)',
      //     },
      //     {
      //       label: 'Grid Availability',
      //       data: trendGroupData.map((a)=>{
      //         return a[5]
      //       }),
      //       borderColor: 'rgb(60,104,17)',
      //       backgroundColor: 'rgba(60,104,17, 0.5)',
      //     },
      //   ],
      // };

      let max = parseFloat(plant.ACMW).toFixed(2) * 1000

      trendOptionData1 = {
        labels,
        datasets: [
          {
            type: 'line',
            label: 'Voltage RY',
            data: trendGroupData.map((a)=>{
              return a[1]
            }),
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
            tension: 0.5,
            yAxisID: 'y'
          },
          {
            type: 'line',
            label: 'Voltage YB',
            data: trendGroupData.map((a)=>{
              return a[2]
            }),
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
            tension: 0.5,
            yAxisID: 'y1'
          },
          {
            type: 'line',
            label: 'Voltage BR',
            data: trendGroupData.map((a)=>{
              return a[3]
            }),
            borderColor: 'rgb(130,119,7)',
            backgroundColor: 'rgba(130,119,7, 0.5)',
            tension: 0.5,
            yAxisID: 'y2'
          },
          {
            type: 'line',
            label: 'Current R',
            data: trendGroupData.map((a)=>{
              return a[4]
            }),
            borderColor: 'rgb(111,14,120)',
            backgroundColor: 'rgba(111,14,120, 0.5)',
            tension: 0.5,
            yAxisID: 'y3'
          },
          {
            type: 'line',
            label: 'Current Y',
            data: trendGroupData.map((a)=>{
              return a[5]
            }),
            borderColor: 'rgb(60,104,17)',
            backgroundColor: 'rgba(60,104,17, 0.5)',
            tension: 0.5,
            yAxisID: 'y4'
          },
          {
            type: 'line',
            label: 'Current B',
            data: trendGroupData.map((a)=>{
              return a[6]
            }),
            borderColor: 'rgb(223,58,22)',
            backgroundColor: 'rgba(223,58,22, 0.5)',
            tension: 0.5,
            yAxisID: 'y5'
          },
          {
            type: 'line',
            label: 'Active Power',
            data: trendGroupData.map((a)=>{
              return a[7]
            }),
            borderColor: 'rgb(237,217,19)',
            backgroundColor: 'rgba(237,217,19, 0.5)',
            tension: 0.5,
            yAxisID: 'y6'
          },
        ],
      };


      let maxTicks = trendGroupData.length/15

      addOptions = {
        maintainAspectRatio: false,
        scales: {
          x: {
            title: {
              display: true,
              text: 'Time'
            },
            ticks: {
              maxTicksLimit: maxTicks,
            }
          },
          y: {
            grid: {
              display: false,
            },
            type: 'linear',
            display: true,
            position: 'left',
            border: {
              display: true,
              color: 'rgb(255, 99, 132)'
            },
            title: {
              display: true,
              text: 'V'
            },
            ticks: {
              suggestedMin: 0
            },
            min: 0,
            // suggestedMax: 100,
          },
          y1: {
            grid: {
              display: false,
            },
            type: 'linear',
            display: true,
            position: 'left',
            border: {
              display: true,
              color: 'rgb(53, 162, 235)'
            },
            title: {
              display: true,
              text: 'V'
            },
            ticks: {
              suggestedMin: 0
            },
            min: 0,
            // suggestedMax: 100,
          },
          y2: {
            grid: {
              display: false,
            },
            type: 'linear',
            display: true,
            position: 'left',
            border: {
              display: true,
              color: 'rgb(130,119,7)'
            },
            title: {
              display: true,
              text: 'V'
            },
            ticks: {
              suggestedMin: 0
            },
            min: 0,
            // suggestedMax: 100,
          },
          y3: {
            grid: {
              display: false,
            },
            type: 'linear',
            display: true,
            position: 'right',
            border: {
              display: true,
              color: 'rgb(111,14,120)'
            },
            title: {
              display: true,
              text: 'A'
            },
            ticks: {
              suggestedMin: 0
            },
            min: 0,
            // suggestedMax: 100,
          },
          y4: {
            grid: {
              display: false,
            },
            type: 'linear',
            display: true,
            position: 'right',
            border: {
              display: true,
              color: 'rgb(60,104,17)'
            },
            title: {
              display: true,
              text: 'A'
            },
            ticks: {
              suggestedMin: 0
            },
            min: 0,
            // suggestedMax: 100,
          },
          y5: {
            grid: {
              display: false,
            },
            type: 'linear',
            display: true,
            position: 'right',
            border: {
              display: true,
              color: 'rgb(223,58,22)'
            },
            title: {
              display: true,
              text: 'A'
            },
            ticks: {
              suggestedMin: 0
            },
            min: 0,
            // suggestedMax: 100,
          },
          y6: {
            grid: {
              display: false,
            },
            type: 'linear',
            display: true,
            position: 'right',
            border: {
              display: true,
              color: 'rgb(237,217,19)'
            },
            title: {
              display: true,
              text: 'kW'
            },
            ticks: {
              suggestedMin: 0
            },
            min: 0,
            suggestedMax: max,
          }
        },
      }

     
    }

    if (id === "2") {

      chartType = "mixed"

      renderArray = trendData.data[0] ? 
      trendData.data[0].transformer.map((trf)=>{
        if (trf.acdb) {
          return trf.acdb.map((ac)=>{
            if (ac.inverter) {
              return ac.inverter.map((inv)=>{
                if (inv.tags) {
                  return inv.invID
                } else {
                  return null
                }
              }).flat()
            } else {
              return null
            }
          }).flat()
        } else {
          return trf.inverter.map((inv)=>{
            if (inv.tags) {
              return inv.invID
            } else {
              return null
            }
          }).flat()
        }
      }).flat()
      : null

      if (deviceID === 0) {
        
        if (renderArray.length == 1) {
          setDeviceID(renderArray[0])
          return
        }

        const content = renderArray.map((inv, index)=>{
            return (
                <ul key={index}>
                    <li>
                    <Link to="" onClick={()=>setDeviceID(inv)}>Inverter {inv}</Link>
                    </li>
                </ul>
                
            )
        })
        
return (
    <div>
        <Modal content={content}/>
    </div>
    );

      }

      headingContent = <div style={{marginBottom: 10}} >
        <h1>INVERTER {deviceID ? deviceID : ""} GRAPH</h1>
        {(trendData.data.length !== 0 && renderArray.length !== 1) && <Link to="" onClick={()=>setDeviceID(0)}><span align="right">Click here for the inverter list</span></Link>};
        </div>

      if (trendData.data.length === 0) {
        return <div>
        {renderHeader()}
        <h1 style={{display: 'flex', justifyContent: "center"}}>No Data</h1>
        </div>
      }


      const getInvData = (plantArray, deviceID) =>{
        return plantArray.map((plant)=>{
          return plant.transformer.map((trf)=>{
            if (trf.acdb) {
              return trf.acdb.map((ac)=>{
                if (ac.inverter) {
                  return ac.inverter.map((inv)=>{
                      let filterArray = ['DC_PWR', 'ACT_PWR', 'TEMP' ]
                    if (inv.tags && inv.invID === deviceID) {
                      return [plant.mqttTime, inv.tags.filter((tag)=>{
                        return filterArray.includes(tag.tagID)
                      })]
                    } else {
                      return null
                    }
                  }).flat()
                } else {
                  return null
                }
              }).flat()
            } else if (trf.inverter) {
              // code if acdb is not available
              return trf.inverter.map((inv)=>{
                let filterArray = ['DC_PWR', 'ACT_PWR', 'TEMP' ]
              if (inv.tags && inv.invID === deviceID) {
                return [plant.mqttTime, inv.tags.filter((tag)=>{
                  return filterArray.includes(tag.tagID)
                })]
              } else {
                return null
              }
            }).flat()
            } else {
              return null
            }
          }).flat()
        })

      }

      
      let trendData1 = getInvData(trendData.data, deviceID).map((data)=>{
        return data.filter(item=>item)
    })

    if (trendData1[0].length === 0) {
      return (
        <div>Loading...</div>
      )
    }
      

      let flatten = (ary) => {
        return ary.map((ar)=>{
          return [ar[0].split(" ")[1], ar[1].map((tag)=>{
            return tag.tagValue
          }).map(Number), parseFloat(ar[1][1].tagValue).toFixed(2)/parseFloat(ar[1][0].tagValue).toFixed(2)*100 || 0].flat()
        })
      }
      
      
      const trendGroupData = flatten(trendData1).sort(function(a, b){return a[0].localeCompare(b[0])})

      if (!trendGroupData || trendGroupData.length === 0) {
        return <div>
        {renderHeader()}
        <h1 style={{display: 'flex', justifyContent: "center"}}>No Data</h1>
        </div>
      }

      const labels = trendGroupData.map((a)=>{
        return a[0].slice(0, -3)
      });

      trendOptionData1 = {
        labels,
        datasets: [
          {
            type: 'line',
            label: 'DC Power',
            data: trendGroupData.map((a)=>{
              return a[1]
            }),
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
            tension: 0.5,
            yAxisID: 'y'
          },
          {
            type: 'line',
            label: 'AC Power',
            data: trendGroupData.map((a)=>{
              return a[2]
            }),
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
            tension: 0.5,
            yAxisID: 'y1'
          },
          {
            type: 'line',
            label: 'Temp',
            data: trendGroupData.map((a)=>{
              return a[3]
            }),
            borderColor: 'rgb(130,119,7)',
            backgroundColor: 'rgba(130,119,7, 0.5)',
            tension: 0.5,
            yAxisID: 'y2'
          },
          {
            type: 'line',
            label: 'Efficiency',
            data: trendGroupData.map((a)=>{
              return a[4]
            }),
            borderColor: 'rgb(111,14,120)',
            backgroundColor: 'rgba(111,14,120, 0.5)',
            tension: 0.5,
            yAxisID: 'y3'
          },

        ],
      };

      let maxTicks = trendGroupData.length/15

      addOptions = {
        maintainAspectRatio: false,
        scales: {
          x: {
            title: {
              display: true,
              text: 'Time'
            },
            ticks: {
              maxTicksLimit: maxTicks,
            }
          },
          y: {
            grid: {
              display: false,
            },
            type: 'linear',
            display: true,
            position: "left",
            border: {
              display: true,
              color: 'rgb(255, 99, 132)'
            },
            title: {
              display: true,
              text: 'kW'
            },
            ticks: {
              suggestedMin: 0
            }
          },
          y1: {
            grid: {
              display: false,
            },
            type: 'linear',
            display: true,
            position: "left",
            border: {
              display: true,
              color: 'rgb(53, 162, 235)'
            },
            title: {
              display: true,
              text: 'kW'
            },
            ticks: {
              suggestedMin: 0
            }
          },
          y2: {
            grid: {
              display: false,
            },
            type: 'linear',
            display: true,
            position: "right",
            border: {
              display: true,
              color: 'rgb(130,119,7)'
            },
            title: {
              display: true,
              text: '°C'
            },
            ticks: {
              suggestedMin: 0
            }
          },
          y3: {
            grid: {
              display: false,
            },
            type: 'linear',
            display: true,
            position: "right",
            border: {
              display: true,
              color: 'rgb(111,14,120)'
            },
            title: {
              display: true,
              text: '%'
            },
            ticks: {
              suggestedMin: 0
            }
          },
        },
      }

      
    }


    if (id === "3") {

      chartType = "mixed"

      renderArray = trendData.data[0] ? 
      trendData.data[0].transformer.map((trf)=>{
        return trf.trfID
      })
      : null

      if (deviceID === 0) {

       if (renderArray.length == 1) {
          setDeviceID(renderArray[0])
          return
        }

        const content = renderArray.map((trf, index)=>{
            return (
                <ul key={index}>
                    <li>
                    <Link to="" onClick={()=>setDeviceID(trf)}>Transformer {trf}</Link>
                    </li>
                </ul>
                
            )
        })
    
    return (
        <div>
            <Modal content={content}/>
        </div>
        );

      }

      headingContent = <div style={{marginBottom: 10}} >
        <h1>TRANSFORMER {deviceID ? deviceID : ""} GRAPH</h1>
        {(trendData.data.length !== 0 && renderArray.length !== 1) && <Link to="" onClick={()=>setDeviceID(0)}><span align="right">Click here for the transformer list</span></Link>};
        </div>

      if (trendData.data.length === 0) {
        return <div>
        {renderHeader()}
        <h1 style={{display: 'flex', justifyContent: "center"}}>No Data</h1>
        </div>
      }

     

      const getTrfData = (plantArray, deviceID) =>{
        return plantArray.map((plant)=>{
          return plant.transformer.map((trf)=>{
            let filterArray = ['OIL_TEMP', 'WINDING_TEMP' ]
            if (trf.tags && trf.trfID === deviceID) {
              return [plant.mqttTime, trf.tags.filter((tag)=>{
                return filterArray.includes(tag.tagID)
              })]
            } else {
              return 0
            }
            
          }).flat()
        })

      }

      let trendData1 = getTrfData(trendData.data, deviceID).map((data)=>{
        return data.filter(item=>item)
    })

    if (trendData1[0].length === 0) {
      return (
        <div>Loading...</div>
      )
    }


      let flatten = (ary) => {
        return ary.map((ar)=>{
          return [ar[0].split(" ")[1], ar[1].map((tag)=>{
            return tag.tagValue
          }).map(Number)].flat()
        })
      }

      
      const trendGroupData = flatten(trendData1).sort(function(a, b){return a[0].localeCompare(b[0])})

      if (!trendGroupData || trendGroupData.length === 0) {
        return <div>
        {renderHeader()}
        <h1 style={{display: 'flex', justifyContent: "center"}}>No Data</h1>
        </div>
      }

      const labels = trendGroupData.map((a)=>{
        return a[0].slice(0, -3)
      });

      trendOptionData1 = {
        labels,
        datasets: [
          {
            type: 'line',
            label: 'Oil Temperature',
            data: trendGroupData.map((a)=>{
              return a[1]
            }),
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
            tension: 0.5,
            yAxisID: 'y'
          },
          {
            type: 'line',
            label: 'Winding Temperature',
            data: trendGroupData.map((a)=>{
              return a[2]
            }),
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
            tension: 0.5,
            yAxisID: 'y1'
          },
          
        ],
      };

      let maxTicks = trendGroupData.length/15

      addOptions = {
        maintainAspectRatio: false,
        scales: {
          x: {
            title: {
              display: true,
              text: 'Time'
            },
            ticks: {
              maxTicksLimit: maxTicks,
            }
          },
          y: {
            type: 'linear',
            display: true,
            position: 'left',
            border: {
              display: true,
              color: 'rgb(255, 99, 132)'
            },
            title: {
              display: true,
              text: '°C'
            },
            ticks: {
              suggestedMin: 0
            },
            suggestedMin: 0,
            suggestedMax: 100
          },
          y1: {
            type: 'linear',
            display: true,
            position: 'right',
            border: {
              display: true,
              color: 'rgb(53, 162, 235)'
            },
            title: {
              display: true,
              text: '°C'
            },
            ticks: {
              suggestedMin: 0
            },
            suggestedMin: 0,
            suggestedMax: 100
          },
        },
      }

    }

    if (id === "4") {

      chartType = "mixed"

      headingContent = <h1>30-DAY TRAILING GRAPHS</h1>;

      if (trendData.data.length === 0) {
        return <div>
        {renderHeader()}
        <h1 style={{display: 'flex', justifyContent: "center"}}>No Data</h1>
        </div>
      }

      const getMscData = (array) => {

        if (trendData.data) {
          return array.map((data)=>{
            return [ data.mqttTime, data.msc.map((ms)=>{
              let filterArray = ['VC_TKWH', "IR_TOD", "PAVL_TOD", "GRD_AVLT"]
              return ms.tags.filter((tag)=>{
                return filterArray.includes(tag.tagID)
              })
            })]
          })
        }
  
      }
  
      let trendData1 = getMscData(trendData.data).map((data)=>{
          return [data[0], data[1].filter((inData)=>{
            return inData.length !==0
          })]
        
      })

      const flatten = (ary) =>{
        return ary.map((ar)=>{
          
          return [ar[0].split(" ")[0], ar[1].reduce((a, b) => a.concat(b[0].tagValue), []).map(Number) ].flat()
        })
      }


      const trendGroupData = flatten(trendData1).sort(function(a, b){return a[0].localeCompare(b[0])})

      if (!trendGroupData || trendGroupData.length === 0) {
        return <div>
        {renderHeader()}
        <h1 style={{display: 'flex', justifyContent: "center"}}>No Data</h1>
        </div>
      }

      const labels = trendGroupData.map((a)=>{
        return moment(a[0]).format("DD-MM-YY")
      });

      trendOptionData1 = {
        labels,
        datasets: [
          {
            type: 'bar',
            label: 'Daily Generation',
            data: trendGroupData.map((a)=>{
              return a[1]
            }),
            borderColor: 'rgb(108,158,220)',
            backgroundColor: 'rgba(108,158,220, 0.5)',
            tension: 0.5,
            yAxisID: 'y'
          },
          {
            type: 'line',
            label: 'IR Daily',
            data: trendGroupData.map((a)=>{
              return a[2]
            }),
            borderColor: 'rgb(12,81,133)',
            backgroundColor: 'rgba(12,81,133, 0.5)',
            tension: 0.5,
            yAxisID: 'y1'
          },
          {
            type: 'line',
            label: 'PAVL Daily',
            data: trendGroupData.map((a)=>{
              return a[3]
            }),
            borderColor: 'rgb(223,205,24)',
            backgroundColor: 'rgba(223,205,24, 0.5)',
            tension: 0.5,
            yAxisID: 'y2'
          },
          {
            type: 'line',
            label: 'GAVL Daily',
            data: trendGroupData.map((a)=>{
              return a[4]
            }),
            borderColor: 'rgb(91,227,27)',
            backgroundColor: 'rgba(91,227,27, 0.5)',
            tension: 0.5,
            yAxisID: 'y3'
          },
        ],
      };

      addOptions = {
        maintainAspectRatio: false,
        scales: {
          x: {
            title: {
              display: true,
              text: 'Date'
            }
          },
          y: {
            type: "linear",
            display: true,
            position: "left",
            border: {
              display: true,
              color: "rgb(108,158,220)"
            },
            title: {
              display: true,
              text: 'kWh'
            },
            suggestedMin: 0,
            suggestedMax: 10000
          },
          y1: {
            type: "linear",
            display: true,
            position: "left",
            border: {
              display: true,
              color: "rgb(12,81,133)"
            },
            title: {
              display: true,
              text: 'kwh/m²'
            },
            suggestedMin: 0,
            suggestedMax: 10
          },
          y2: {
            type: "linear",
            display: true,
            position: "right",
            border: {
              display: true,
              color: "rgb(223,205,24)"
            },
            title: {
              display: true,
              text: '%'
            },
            min: 0,
            max: 100
          },
          y3: {
            type: "linear",
            display: true,
            position: "right",
            border: {
              display: true,
              color: "rgb(91,227,27)"
            },
            title: {
              display: true,
              text: '%'
            },
            min: 0,
            max: 100
          }
        },
      }
      

    }

    if (id === "5") {

      chartType = "mixed"

      headingContent = <h1>COUNTER TREND</h1>;

      if (trendData.data.length === 0) {
        return <div>
        {renderHeader()}
        <h1 style={{display: 'flex', justifyContent: "center"}}>No Data</h1>
        </div>
      }

      const getCTrendData = (array) => {

        if (trendData.data) {
          return array.map((data)=>{
            return [data.mqttTime, data.counterTrend.map((CT)=>{
              if (CT.tags) {
                return CT.tags
              }
            })] 
          })
        }
  
      }

      const flatten = (ary) =>{
        return ary.map((ar)=>{
          
          return [ar[0].split(" ")[1], ar[1].reduce((a, b) => a.concat(b[0].tagValue), []).map(Number)].flat()
        })
      }

      const trendGroupData = flatten(getCTrendData(trendData.data)).sort(function(a, b){return a[0].localeCompare(b[0])})

      if (!trendGroupData || trendGroupData.length === 0) {
        return <div>
        {renderHeader()}
        <h1 style={{display: 'flex', justifyContent: "center"}}>No Data</h1>
        </div>
      }

      const labels = trendGroupData.map((a)=>{
        return a[0].slice(0, -3)
      });

      trendOptionData1 = {
        labels,
        datasets: [
          {
            type: 'line',
            label: 'PR DAILY  INC COUNTER',
            data: trendGroupData.map((a)=>{
              return a[1]
            }),
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
            tension: 0.5,
            yAxisID: 'y'
          },
          {
            type: 'line',
            label: 'PR DAILY  BUFFER',
            data: trendGroupData.map((a)=>{
              return a[2]
            }),
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
            tension: 0.5,
            yAxisID: 'y1'
          },
          {
            type: 'line',
            label: 'PR YEARLY INC COUNTER',
            data: trendGroupData.map((a)=>{
              return a[3]
            }),
            borderColor: 'rgb(130,119,7)',
            backgroundColor: 'rgba(130,119,7, 0.5)',
            tension: 0.5,
            yAxisID: 'y2'
          },
          {
            type: 'line',
            label: 'PR YEARLY BUFFER',
            data: trendGroupData.map((a)=>{
              return a[4]
            }),
            borderColor: 'rgb(111,14,120)',
            backgroundColor: 'rgba(111,14,120, 0.5)',
            tension: 0.5,
            yAxisID: 'y3'
          },
          {
            type: 'line',
            label: 'PAVL & GAVL DAILY INC COUNTER',
            data: trendGroupData.map((a)=>{
              return a[5]
            }),
            borderColor: 'rgb(60,104,17)',
            backgroundColor: 'rgba(60,104,17, 0.5)',
            tension: 0.5,
            yAxisID: 'y4'
          },
          
          {
            type: 'line',
            label: 'PAVL & GAVL YEARLY INC COUNTER',
            data: trendGroupData.map((a)=>{
              return a[6]
            }),
            borderColor: 'rgb(185,243,244)',
            backgroundColor: 'rgba(185,243,244, 0.5)',
            tension: 0.5,
            yAxisID: 'y5'
          },
          {
            type: 'line',
            label: 'PAVL DAILY BUFFER',
            data: trendGroupData.map((a)=>{
              return a[7]
            }),
            borderColor: 'rgb(245,244,146)',
            backgroundColor: 'rgba(245,244,146, 0.5)',
            tension: 0.5,
            yAxisID: 'y6'
          },
          {
            type: 'line',
            label: 'PAVL YEARLY BUFFER',
            data: trendGroupData.map((a)=>{
              return a[8]
            }),
            borderColor: 'rgb(38,44,147)',
            backgroundColor: 'rgba(38,44,147, 0.5)',
            tension: 0.5,
            yAxisID: 'y7'
          },
          {
            type: 'line',
            label: 'GAVL DAILY BUFFER',
            data: trendGroupData.map((a)=>{
              return a[9]
            }),
            borderColor: 'rgb(198,139,22)',
            backgroundColor: 'rgba(198,139,22, 0.5)',
            tension: 0.5,
            yAxisID: 'y8'
          },
          {
            type: 'line',
            label: 'GAVL YEARLY  BUFFER',
            data: trendGroupData.map((a)=>{
              return a[10]
            }),
            borderColor: 'rgb(62,13,13)',
            backgroundColor: 'rgba(62,13,13, 0.5)',
            tension: 0.5,
            yAxisID: 'y9'
          },
        ],
      };

      let maxTicks = trendGroupData.length/15

      addOptions = {
        maintainAspectRatio: false,
        scales: {
          x: {
            title: {
              display: true,
              text: 'Time',
            },
            ticks: {
              maxTicksLimit: maxTicks,
            }
          },
          y: {
            grid: {
              display: false,
            },
            type: 'linear',
            display: true,
            position: 'left',
            border: {
              display: true,
              color: 'rgb(255, 99, 132)',
              width: 2
            },
            title: {
              display: true,
              text: ''
            },
            ticks: {
              suggestedMin: 0
            },
            suggestedMin: 0,
            // suggestedMax: 100
          },
          y1: {
            grid: {
              display: false,
            },
            type: 'linear',
            display: true,
            position: 'left',
            border: {
              display: true,
              color: 'rgb(53, 162, 235)',
              width: 2
            },
            title: {
              display: true,
              text: ''
            },
            ticks: {
              suggestedMin: 0
            },
            suggestedMin: 0,
            // suggestedMax: 100
          },
          y2: {
            grid: {
              display: false,
            },
            type: 'linear',
            display: true,
            position: 'left',
            border: {
              display: true,
              color: 'rgb(130,119,7)',
              width: 2
            },
            title: {
              display: true,
              text: ''
            },
            ticks: {
              suggestedMin: 0
            },
            suggestedMin: 0,
            // suggestedMax: 100
          },
          y3: {
            grid: {
              display: false,
            },
            type: 'linear',
            display: true,
            position: 'left',
            border: {
              display: true,
              color: 'rgb(111,14,120)',
              width: 2
            },
            title: {
              display: true,
              text: ''
            },
            ticks: {
              suggestedMin: 0
            },
            suggestedMin: 0,
            // suggestedMax: 100
          },
          y4: {
            grid: {
              display: false,
            },
            type: 'linear',
            display: true,
            position: 'left',
            border: {
              display: true,
              color: 'rgb(60,104,17)',
              width: 2
            },
            title: {
              display: true,
              text: ''
            },
            ticks: {
              suggestedMin: 0
            },
            suggestedMin: 0,
            // suggestedMax: 100
          },
          y5: {
            grid: {
              display: false,
            },
            type: 'linear',
            display: true,
            position: 'right',
            border: {
              display: true,
              color: 'rgb(185,243,244)',
              width: 2
            },
            title: {
              display: true,
              text: ''
            },
            ticks: {
              suggestedMin: 0
            },
            suggestedMin: 0,
            // suggestedMax: 100
          },
          y6: {
            grid: {
              display: false,
            },
            type: 'linear',
            display: true,
            position: 'right',
            border: {
              display: true,
              color: 'rgb(245,244,146)',
              width: 2
            },
            title: {
              display: true,
              text: ''
            },
            ticks: {
              suggestedMin: 0
            },
            suggestedMin: 0,
            // suggestedMax: 100
          },
          y7: {
            grid: {
              display: false,
            },
            type: 'linear',
            display: true,
            position: 'right',
            border: {
              display: true,
              color: 'rgb(38,44,147)',
              width: 2
            },
            title: {
              display: true,
              text: ''
            },
            ticks: {
              suggestedMin: 0
            },
            suggestedMin: 0,
            // suggestedMax: 100
          },
          y8: {
            grid: {
              display: false,
            },
            type: 'linear',
            display: true,
            position: 'right',
            border: {
              display: true,
              color: 'rgb(198,139,22)',
              width: 2
            },
            title: {
              display: true,
              text: ''
            },
            ticks: {
              suggestedMin: 0
            },
            suggestedMin: 0,
            // suggestedMax: 100
          },
          y9: {
            grid: {
              display: false,
            },
            type: 'linear',
            display: true,
            position: 'right',
            border: {
              display: true,
              color: 'rgb(62,13,13)',
              width: 2
            },
            title: {
              display: true,
              text: ''
            },
            ticks: {
              suggestedMin: 0
            },
            suggestedMin: 0,
            // suggestedMax: 100
          }
        },
      }
      
      

    }

    return (
        <div style={{overflow: 'auto', height: '75vh', marginLeft: 20}}>
          {renderHeader()}
          {trendData.data && trendData.data.length !== 0 ? 
          <ChartComponent optionData={trendOptionData1} addOptions={addOptions} chartType={chartType}/>
        :
        <div>
        <h1 style={{display: 'flex', justifyContent: "center"}}>No Data</h1>
        </div>
        }

          
        </div>
    );
}

const mapStateToProps = (state) => {
      return {plantID: state.plantID.data, isLoggedIn: state.auth.isLoggedIn, trendData: state.trendData, plant: state.plant.data ? state.plant.data.data : null }
}

export default connect(mapStateToProps, { getDataForTrends })(ChartPage);
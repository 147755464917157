import sidebarReducer from "./sidebarReducer"
import messageReducer from "./message"
import authReducer from "./auth"
import plantReducer from "./plantReducer";
import plantIDReducer from "./PlantIDReducer";
import tableVisibilityReducer from "./tableVisibilityReducer";
import mqttRangeReducer from "./MqttRangeReducer";
import plantSelectorVisibility from "./PlantSelectorVisibility";
import alarmReducer from "./alarmReducer";
import userReducer from "./userReducer";
import plantsReducer from "./plantsReducer";
import alarmRangeReducer from "./alarmRangeReducer";
import reportReducer from "./ReportReducer";
import mobileReducer from "./mobileReducer";
import iconPathReducer from "./iconPathReducer";


export const rootReducer = {
    auth: authReducer,
    sidebar: sidebarReducer,
    message: messageReducer,
    plant: plantReducer,
    plants: plantsReducer,
    plantID: plantIDReducer,
    tableShown: tableVisibilityReducer,
    trendData: mqttRangeReducer,
    selectorVisibility: plantSelectorVisibility,
    activeAlarms: alarmReducer,
    alarmRange: alarmRangeReducer,
    users: userReducer,
    report: reportReducer,
    isMobile: mobileReducer,
    iconPath: iconPathReducer    
    
};
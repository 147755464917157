import { GET_ALL_ACTIVE_ALARMS, RESET_ACTIVE_ALARMS } from "../actions/types";


const initialState = {data: null};

const alarmReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_ACTIVE_ALARMS:
      return { ...state, data: payload };
    case RESET_ACTIVE_ALARMS:
        return initialState;
    default:
      return state;
  }
}

export default alarmReducer;
import { IS_MOBILE, RESET_IS_MOBILE } from "../actions/types";

let initialState = {isMobile: false}

const mobileReducer = (state = initialState, action) => {
    switch (action.type) {
        case IS_MOBILE:
            return {...state, isMobile: action.payload};
        case RESET_IS_MOBILE:
            return initialState;
        default:
            return state;
    }
}

export default mobileReducer;
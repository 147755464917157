import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
  CLEAR_MESSAGE,
  RESET_PLANT_MQTT_INTERMEDIATE,
  RESET_PLANT_ID,
  ACDB_TABLE_SHOWN_RESET,
  RESET_MQTT_WITH_RANGE,
  RESET_GET_REPORT,
  RESET_VISIBLE_PLANTSELECTOR,
  RESET_ACTIVE_ALARMS,
  RESET_ALL_USERS,
  RESET_ALL_PLANTS,
  RESET_ALARMS_IN_RANGE,
  RESET_ICON_PATH,
} from "./types";

import { toast } from 'react-hot-toast';

import AuthService from "../services/auth.service";

export const signIn = (formValues) => (dispatch) => {
  return AuthService.signIn(formValues).then(
    (data) => {

      if (data.code !== 200) {
        dispatch({
          type: LOGIN_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: data.message,
        });

        toast.error(data.message);

        return;

      }


      dispatch({
        type: LOGIN_SUCCESS,
        payload: data
      });

      toast.success(data.message);
      

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const logout = () => (dispatch) => {
  AuthService.logout();

  dispatch({
    type: LOGOUT,
  });

  dispatch({
    type: CLEAR_MESSAGE,
  })

  dispatch({
    type: RESET_PLANT_MQTT_INTERMEDIATE
  })
  
  dispatch({
    type: RESET_PLANT_ID
  })

  dispatch({
    type: ACDB_TABLE_SHOWN_RESET
  })
  dispatch({
    type: RESET_MQTT_WITH_RANGE
  })

  dispatch({
    type: RESET_GET_REPORT
  })
  dispatch({
    type: RESET_VISIBLE_PLANTSELECTOR
  })
  dispatch({
    type: RESET_ACTIVE_ALARMS
  })
  dispatch({
    type: RESET_ALL_USERS
  })
  dispatch({
    type: RESET_ALL_PLANTS
  })
  dispatch({
    type: RESET_ALARMS_IN_RANGE
  })
  dispatch({
    type: RESET_ICON_PATH
  })
};

import 'semantic-ui-css/semantic.min.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import React, { useEffect } from 'react';

import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { isMobile } from 'react-device-detect';
import { connect, useDispatch } from 'react-redux';

import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";

import Layout from './components/Layout';

import { clearMessage } from './actions/message';
import SingleLineDiagram from './pages/SingleLineDiagram';
import AcdbTable from './pages/AcdbTable';
import VcbTable from './pages/VcbTable';
import InverterTable from './pages/InverterTable';
import UserManagement from './pages/UserManagement';
import PlantSelector from './pages/PlantSelector';
import ChartPage from './pages/ChartPage';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import ActiveAlarms from './pages/ActiveAlarms';
import MailPage from './pages/MailPage';
import PlantManagement from './pages/PlantManagement';
import AllAlarms from './pages/AllAlarms';
import ReportPage from './pages/ReportPage';
import IconUpload from './pages/IconUpload';
import { IS_MOBILE } from './actions/types';
import MapComponent from './components/MapComponent';
import PrivacyPolicy from './pages/PrivacyPolicy'; 
import DeleteRequest from './pages/DeleteRequest';

const App = (props) => {
  const { clearMessage, isLoggedIn, role } = props
  
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    
    if (["/login"].includes(location.pathname)) {
      clearMessage(); // clear message when changing location
    }

    if (isMobile) {
      dispatch({
        type: IS_MOBILE,
        payload: true
      })      
    } else {
      dispatch({
        type: IS_MOBILE,
        payload: false
      })            
    }
  }, [clearMessage, location, isMobile]);

  
  return (

      <div>
      <Layout> 
        <Routes>
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/forgotpassword" element={!isLoggedIn ? <ForgotPassword /> : <Navigate to="/login" />} />
          <Route exact path="/resetpassword/:resetToken" element={!isLoggedIn ? <ResetPassword /> : <Navigate to="/login" />} />
          <Route exact path="/" element = {isLoggedIn ? <Dashboard /> : <Navigate to="/login" />} />
          <Route exact path="/dashboard" element={isLoggedIn ? <Dashboard /> : <Navigate to="/login" />} />
          <Route exact path="/sld" element={isLoggedIn ? <SingleLineDiagram /> : <Navigate to="/login" />} />
          {/* <Route exact path="/acdbTable" element={isLoggedIn && role === 'superAdmin' ? <AcdbTable /> : <Navigate to="/login" />} />
          <Route exact path="/vcbTable" element={isLoggedIn && role === 'superAdmin' ? <VcbTable /> : <Navigate to="/login" />} />
          <Route exact path="/invTable" element={isLoggedIn && role === 'superAdmin' ? <InverterTable /> : <Navigate to="/login" />} /> */}
          <Route exact path="/users" element={isLoggedIn && role === 'superAdmin' ? <UserManagement /> : <Navigate to="/login" />} />
          <Route exact path="/plants" element={isLoggedIn && role === 'superAdmin' ? <PlantManagement /> : <Navigate to="/login" />} />
          <Route exact path="/plantselector" element={isLoggedIn ? <PlantSelector /> : <Navigate to="/login" />} />
          <Route exact path="/activealarms" element={isLoggedIn ? <ActiveAlarms /> : <Navigate to="/login" />} />
          <Route exact path="/allalarms" element={isLoggedIn ? <AllAlarms /> : <Navigate to="/login" />} />
          <Route exact path="/reports" element={isLoggedIn ? <ReportPage /> : <Navigate to="/login" />} />
          <Route exact path="/iconupload" element={isLoggedIn ? <IconUpload /> : <Navigate to="/login" />} />
          {/* <Route exact path="/sendmail" element={isLoggedIn ? <MailPage /> : <Navigate to="/login" />} /> */}
          <Route exact path="/maps" element={isLoggedIn ? <MapComponent /> : <Navigate to="/login" />} />
          <Route exact path="/chart/:id" element={isLoggedIn ? <ChartPage /> : <Navigate to="/login" />} />
          <Route exact path="/privacypolicies" element={<PrivacyPolicy />} />
          <Route path="/requestdelete/:requestToken?" element={<DeleteRequest />} />
          <Route path="*" element={<Navigate to="/dashboard" />} />
          
        </Routes>
        </Layout>
        
      </div>
      
  );
}

const mapStateToProps = (state) =>{
  return { isLoggedIn: state.auth.isLoggedIn, role: state.auth.user ? state.auth.user.payload.role : null }
}

export default connect(mapStateToProps, { clearMessage })(App);

import { PLANT_MQTT_WITH_RANGE, RESET_MQTT_WITH_RANGE } from "../actions/types";

let initialState = {}

const plantReducer = (state = initialState, action) => {
    switch (action.type) {
        case PLANT_MQTT_WITH_RANGE:
            return {...state, data: action.payload};
        case RESET_MQTT_WITH_RANGE:
            return initialState;
        default:
            return state;
    }
}

export default plantReducer;